import { Map as IMap } from 'immutable';


export const CART_STATUS = {
    INVALID: 'invalid',
    PROCESSING: 'processing',
    READY: 'ready',
    REDIRECT_TO_DESIGN: 'redirect-to-design'
};


export const SET_CART_STATUS = '@@storefront/SET_CART_STATUS';
export const CART_CHECKOUT_STARTED = '@@storefront/CHECKOUT_STARTED';
export const CART_VIEWED = '@@storefront/CART_VIEWED';



export default function cartReducer(state = IMap({}), action) {
    switch (action.type) {
        case SET_CART_STATUS:
            return state.set('cartStatus', action.status);
        default:
            return state;
    }
}




export function setCartStatusProcessing(replaceHistory = false) {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.PROCESSING,
        replaceHistory
    };
}


export function setCartStatusInvalid() {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.INVALID
    };
}

export function setCartStatusRedirectToDesign() {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.REDIRECT_TO_DESIGN
    };
}



export function setCartStatusReady(replaceHistory = false) {
    return {
        type: SET_CART_STATUS,
        status: CART_STATUS.READY,
        replaceHistory
    };
}

export function setCartViewed(eventData) {
    return {
        type: CART_VIEWED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Cart Viewed',
                eventData
            }
        }
    };
}

export function setCartCheckoutStarted(eventData) {
    return {
        type: CART_CHECKOUT_STARTED,
        meta: {
            segment: {
                event: 'track',
                eventAction: 'Checkout Started',
                eventData
            }
        }
    };
}
