import { Map as IMap, List as IList, Set as ISet } from 'immutable';
import { createSelector } from 'reselect';

import { getCurrencyCodes } from '../item/itemSelectors';
import {
    AMOUNT_FIELD,
    FACEPLATE_FIELD,
    IS_PLASTIC_FIELD,
    PROGRAM_CODE,
    CURRENCY_FIELD,
    IS_SELFBUY_FIELD,
    RECIPIENT_NAME_FIELD,
    SENDER_NAME_FIELD,
    RECIPIENT_EMAIL_FIELD,
    MESSAGE_FIELD,
    ITEM_ACCESSORY
} from '../item/newItemForm';
import { HEADER_FLOW_PATHS } from './flowConstants';

import { history } from './routing';
import {
    PREFERRED_CLASSIC_GIFT,
    PREFERRED_FACEPLATE_CATEGORY
} from './flowProgressModule';
import { isProgramSelectionEnabled } from '../program/programSelectors';


export const getFlowState = state => state.get('flow', IMap());


export const getFlowConfig = createSelector(
    getFlowState,
    flow => flow.get('flowConfig')
);


export const getBuyFlowConfig = createSelector(
    getFlowConfig,
    flowConfig => flowConfig.get('buy')
);


export const getPostBuyFlowConfig = createSelector(
    getFlowConfig,
    flowConfig => flowConfig.get('postBuy')
);


export const getStepConfigs = createSelector(
    getFlowState,
    flow => flow.get('stepConfigs')
);


export const getHomePath = createSelector(
    getBuyFlowConfig,
    flowConfig => flowConfig.first()
);


export const getPathStepConfig = createSelector(
    state => state.getIn(['flow', 'stepConfigs'], IMap()),
    stepConfigs => path => stepConfigs.get(path)
);


export const getLocationStepConfig = (state, location) => {
    const { pathname } = location; 
    const pathParts = new IList(pathname.split('/'))
        .shift() 
        .filter(part => part !== ''); 
    const found = getPathStepConfig(state)(pathParts.last());
    return found || IMap();
};


export const getCurrentLocationStepConfig = (state) => {
    const currentLocation = history.location;
    return getLocationStepConfig(state, currentLocation);
};


export const getShowCartPaths = createSelector(
    getStepConfigs,
    (stepConfigs) => {
        const cartPaths = stepConfigs
            .filterNot(config => config.get('hideCartLink', false))
            .keySeq()
            .map(path => `*/${path}`)
            .toArray();

        return [...HEADER_FLOW_PATHS, ...cartPaths];
    }
);


export const getDisplayBannerPaths = createSelector(
    getStepConfigs,
    (stepConfigs) => {
        
        const bannerPaths = stepConfigs
            .filterNot(config => config.get('hidePromoBanner', false))
            .keySeq()
            .map(path => `*/${path}`)
            .toArray();

        return [...HEADER_FLOW_PATHS, ...bannerPaths];
    }
);

export const getFlowProgressState = state => state.get('flowProgress', IMap());

export const getFlowProgress = createSelector(
    getFlowProgressState,
    flowProgress => flowProgress.get('progress', ISet())
);

export const getPreferredValues = createSelector(
    getFlowProgressState,
    flowProgress => flowProgress.get('preferredValues', IMap())
);

export const getPreferredPaymentValues = createSelector(
    getFlowProgressState,
    flowProgress => flowProgress.get('preferredPaymentValues', IMap())
);

export const getPreferredAmount = createSelector(
    getPreferredValues,
    values => (values ? values.get(AMOUNT_FIELD, 0) : 0)
);

export const getPreferredFaceplateCode = createSelector(
    getPreferredValues,
    values => (values ? values.get(FACEPLATE_FIELD, null) : null)
);

export const getPreferredCardType = createSelector(
    getPreferredValues,
    values => (values ? values.get(IS_PLASTIC_FIELD, null) : null)
);

export const getPreferredFaceplateCategory = createSelector(
    getPreferredValues,
    values => (values ? values.get(PREFERRED_FACEPLATE_CATEGORY, null) : null)
);

export const getPreferredClassicGift = createSelector(
    getPreferredValues,
    values => (values ? values.get(PREFERRED_CLASSIC_GIFT, null) : null)
);

export const getPreferredProgramCode = createSelector(
    getPreferredValues,
    values => (values ? values.get(PROGRAM_CODE, null) : null)
);


export const getPreferredCurrencyCode = createSelector(
    getPreferredValues,
    getCurrencyCodes,
    (values, currencyCodes) => {
        const preferredCurrencyCode = values.get(CURRENCY_FIELD, null);
        if (preferredCurrencyCode) {
            if (currencyCodes.isEmpty()) {
                return preferredCurrencyCode;
            }
            if (currencyCodes.has(preferredCurrencyCode)) {
                return preferredCurrencyCode;
            }
            return null;
        }
        return null;
    }
);

export const getPreferredLoyaltyNumber = createSelector(
    getPreferredPaymentValues,
    preferredPaymentValues => preferredPaymentValues.get('loyaltyNumber', null)
);

export const getPreferredSelfBuy = createSelector(
    getPreferredValues,
    values => (values ? values.get(IS_SELFBUY_FIELD, null) : null)
);

export const getPreferredRecipientName = createSelector(
    getPreferredValues,
    values => (values ? values.get(RECIPIENT_NAME_FIELD, null) : null)
);

export const getPreferredSenderName = createSelector(
    getPreferredValues,
    values => (values ? values.get(SENDER_NAME_FIELD, null) : null)
);

export const getPreferredRecipientEmail = createSelector(
    getPreferredValues,
    values => (values ? values.get(RECIPIENT_EMAIL_FIELD, null) : null)
);

export const getPreferredMessage = createSelector(
    getPreferredValues,
    values => (values ? values.get(MESSAGE_FIELD, null) : null)
);

export const getPreferredAccessory = createSelector(
    getPreferredValues,
    values => (values ? values.get(ITEM_ACCESSORY, null) : null)
);

function isNonDigital(values, cardType, isSelfBuy, programCode, isClassicGift, programSelectionEnabled) {
    const isPlastic = cardType === 'physical';
    const programPickerSelectionMade = !!programCode || !!isClassicGift || !programSelectionEnabled;

    return !values
        || isPlastic
        || cardType === null 
        || isSelfBuy === null
        || !programPickerSelectionMade;
}

function getIsPreCartFlowSkipped(values, cardType, isSelfBuy, programCode, isClassicGift, programSelectionEnabled) {
    if (isNonDigital(values, cardType, isSelfBuy, programCode, isClassicGift, programSelectionEnabled)) {
        return false;
    }

    
    if (isSelfBuy) {
        return values.keySeq().isSuperset([CURRENCY_FIELD, AMOUNT_FIELD, FACEPLATE_FIELD]);
    }
    
    return values.keySeq().isSuperset([
        CURRENCY_FIELD,
        AMOUNT_FIELD,
        FACEPLATE_FIELD,
        RECIPIENT_NAME_FIELD,
        RECIPIENT_EMAIL_FIELD,
        SENDER_NAME_FIELD,
        MESSAGE_FIELD
    ]);
}

export const isPreCartFlowSkipped = createSelector(
    getPreferredValues,
    getPreferredCardType,
    getPreferredSelfBuy,
    getPreferredProgramCode,
    getPreferredClassicGift,
    isProgramSelectionEnabled,
    (values, cardType, isSelfBuy, programCode, isClassicGift, programSelectionEnabled) => (
        getIsPreCartFlowSkipped(values, cardType, isSelfBuy, programCode, isClassicGift, programSelectionEnabled)
    )
);

export const getFlowMessage = createSelector(
    getFlowProgressState,
    flowProgress => flowProgress.get('flowMessage', null)
);
