import { createSelector } from 'reselect';
import { Map as IMap } from 'immutable';



export const getCartState = state => state.get('cart', IMap());


export const getCartStatus = createSelector(
    getCartState,
    cart => cart.get('cartStatus', null)
);
