import isEmptyRule from '../validate/isEmpty';
import { isValidEmail } from '../validate/email';
import { validatePhoneNumberRule } from '../validate/validatePhoneNumberRule';

import messages from './itemMessages';
import { giftCardFieldNames as keys } from './newItemForm';
import { SENDER_EMAIL_FIELD } from '../payment/paymentConstants';
import { isNameValidRule } from '../recipient/validate';
import addressFields from '../addressFields/addressFieldMessages';



export const messageDiff = (message, maxMessageLength) => maxMessageLength - message.length;

export const maxLenMessage = (message, maxMessageLength) => ({
    ...message,
    values: { maxLength: maxMessageLength }
});



export const validateSenderName = value => (
    isEmptyRule(messages.senderNameInvalid)(value)
    || isNameValidRule(addressFields.COMMON.invalidCharacterUsed)(value));
export const validateRecipientName = value => (
    isEmptyRule(messages.recipientNameInvalid)(value)
    || isNameValidRule(addressFields.COMMON.invalidCharacterUsed)(value));
export const validateRecipientEmail = value => (
    (isEmptyRule(messages.emailRequired)(value)
    || (isValidEmail(value) ? null : messages.emailInvalid))
);

export const validateConfirmEmailMatch = (value, allValues, isSender = false) => {
    if (validateRecipientEmail(value)) {
        return messages.emailInvalid;
    }
    if (value !== allValues.get(isSender ? SENDER_EMAIL_FIELD : keys.RECIPIENT_EMAIL_FIELD)) {
        return messages.confirmEmailMatchInvalid;
    }
    return null;
};

export const validateSMS = value => validatePhoneNumberRule(messages.smsInvalid)(value);

export const validateDeliverySMS = value => validatePhoneNumberRule(messages.smsInvalid)(value) || isEmptyRule(messages.smsInvalid)(value);

export const validateMessage = (value, allValues, props) => {
    const { messageCharacterMaxLength } = props;
    return messageDiff(value || 0, messageCharacterMaxLength) < 0
        ? maxLenMessage(messages.messageInvalidTooLong, messageCharacterMaxLength)
        : null;
};

export const validatePlasticNotify = (value, allValues) => (
    allValues.get(keys.RECIPIENT_PLASTIC_NOTIFICATION)
        ? validateRecipientEmail(value, allValues)
        : null
);

export const fieldValidators = {
    validateSenderName,
    validateRecipientName,
    validateRecipientEmail,
    validateSMS,
    validateDeliverySMS,
    validatePlasticNotify,
    validateMessage,
    validateConfirmEmailMatch
};


const warnMessage = (value, allValues, props) => {
    const { messageCharacterMaxLength } = props;
    const charsLeft = messageDiff(value || 0, messageCharacterMaxLength);
    if (charsLeft === 0) {
        return maxLenMessage(messages.messageWarnAtMax, messageCharacterMaxLength);
    } if (charsLeft > 0 && charsLeft < 6) {
        return maxLenMessage(messages.messageWarnNearMax, messageCharacterMaxLength);
    } if (charsLeft < 0) {
        return maxLenMessage(messages.messageInvalidTooLong, messageCharacterMaxLength);
    }
    return null;
};

export const fieldWarnings = { warnMessage };
