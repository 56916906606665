import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { injectIntl, intlShape } from 'react-intl';
import { RadioGroup } from 'cstar-react-primitives/lib/redux-form/microFields/RadioGroup';

import { giftCardFieldNames } from '../item/newItemForm';

import recipientMessages from './recipientMessages';


const { IS_SELFBUY_FIELD } = giftCardFieldNames;

export const SelfBuyFields = ({
    cashbotName,
    intl
}) => {
    const radioOptions = [
        { value: false, children: intl.formatMessage(recipientMessages.notSelfbuy) },
        { value: true, children: intl.formatMessage(recipientMessages.isSelfbuy) }
    ];
    const radioGroupProps = {
        component: RadioGroup,
        name: IS_SELFBUY_FIELD,
        cashbotName,
        legend: intl.formatMessage(recipientMessages.selfBuyLegend),
        styleType: 'picker',
        containerClass: 'tab-container',
        radioButtonClass: 'tab',
        role: 'tablist',
        radioButtonRole: 'tab',
        radioOptions
    };

    return (
        <Field {...radioGroupProps} />
    );
};

SelfBuyFields.propTypes = {
    cashbotName: PropTypes.string.isRequired,
    intl: intlShape.isRequired
};

export default injectIntl(SelfBuyFields);
