import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { getBasePath } from './flow';
import { updateFlowProgress } from './flowProgressModule';
import emptyFunc from '../utils/emptyFunc';
import { newItemIsPlastic, newItemIsSelfBuy } from '../item/newItemForm';
import { newItemIsAccessoriesStepForSelfBuy } from '../accessories/accessoriesUtils';
import { push, replace } from './routing';
import flowStepSubmitMessages from './flowStepSubmitMessages';
import flowMessages from './flowMessages';


import { amountStepSkippable } from '../amount/amountStepSelectors';
import { canAddDigitalItem, canAddPlasticItem } from '../item/itemSelectors';
import { getSupportedUYO } from '../uyo/uyoSelectors';
import { messageStepSkippable } from '../message/messageStepSelectors';
import {
    getPreferredCardType,
    getPreferredRecipientName,
    getPreferredSelfBuy,
    getPreferredSenderName
} from './flowSelectors';
import { getShowProgramSelection } from '../program/programListSelectors';
import { brandCatalogIsLoaded } from '../brand/brandSelectors';
import {
    hasDigital as hasDigitalFaceplates,
    hasFaceplates,
    hasPlastic as hasPlasticFaceplates,
    hasSingleFaceplateForOneCardType
} from '../faceplate/faceplateSelectors';
import { getProgramsLoaded } from '../program/programSelectors';
import { programStepSkippable } from '../program/programStepSelectors';

import { getPreferredValues } from '../app/bootstrap';
import {
    AMOUNT_STEP,
    CART_STEP,
    DELIVERY_STEP,
    DESIGN_STEP,
    MESSAGE_STEP,
    PROGRAM_STEP,
    RECIPIENT_STEP,
    SHIPPING_STEP,
    TYPE_STEP,
    UYO_INIT_STEP,
    UYO_REVIEW_STEP,
    UYO_SELECT_STEP,
    REDIRECTION_ORDER_STEP,
    PRODUCT_DETAIL
} from './flowConstants';
import { changeStep } from './stepUtils';

export const FlowContext = React.createContext(undefined);

export const DEFAULT_FLOW_CONFIG = {
    [PROGRAM_STEP]: {
        next: () => 'recipient',
        previous: emptyFunc,
        canLoad: ({ showProgramSelection }) => showProgramSelection,
        isReady: ({ programsLoaded }) => programsLoaded,
        smartFlowSkip: ({
            programsLoaded,
            canShowPrograms,
            canSkipProgram
        }) => programsLoaded && (!canShowPrograms || canSkipProgram)
    },
    [RECIPIENT_STEP]: {
        next: ({ isSingleCardType }) => {
            if (isSingleCardType) {
                return 'design'; 
            }
            return 'type';
        },
        previous: () => 'program',
        
        canLoad: () => true,
        isReady: ({
            brandCatalogHasLoaded,
            hasDigitalFaceplate,
            hasPlasticFaceplate
        }) => brandCatalogHasLoaded && (hasDigitalFaceplate || hasPlasticFaceplate),
        smartFlowSkip: ({
            preferredSelfBuy,
            preferredRecipientName,
            preferredSenderName
        }) => preferredSelfBuy || (preferredSelfBuy === false && preferredRecipientName && preferredSenderName)
    },
    [TYPE_STEP]: {
        next: ({ isSingleCardType }) => (isSingleCardType ? 'program' : 'design'),
        previous: () => 'recipient',
        canLoad: ({ canAddPlasticItems, canAddDigitalItems }) => canAddPlasticItems && canAddDigitalItems,
        isReady: ({ hasAFaceplate, brandCatalogHasLoaded }) => hasAFaceplate && brandCatalogHasLoaded,
        smartFlowSkip: ({
            preferredCardTypeSelected,
            canAddPlasticItems,
            canAddDigitalItems
        }) => preferredCardTypeSelected || !canAddPlasticItems || !canAddDigitalItems
    },
    [DESIGN_STEP]: {
        next: ({ isUYO }) => (isUYO ? 'uyo-init' : 'amount'),
        previous: () => 'type',
        canLoad: () => false,
        isReady: () => false,
        smartFlowSkip: () => false
    },
    [UYO_INIT_STEP]: {
        previous: () => 'design',
        next: () => 'uyo-select'
    },
    [UYO_SELECT_STEP]: {
        previous: () => 'uyo-init',
        next: () => 'uyo-review'
    },
    [UYO_REVIEW_STEP]: {
        previous: () => 'uyo-select',
        next: () => 'amount'
    },
    [AMOUNT_STEP]: {
        next: ({ isSelfBuy, hasAccessories, isPlastic }) => {
            if (!isSelfBuy || hasAccessories) {
                return 'message';
            }
            if (isPlastic) {
                return 'delivery';
            }
            return null;
        },
        previous: ({ isUYO }) => (isUYO ? 'uyo-review' : 'design')
    },
    [MESSAGE_STEP]: {
        next: ({ isSelfBuy, isPlastic }) => {
            if (!isSelfBuy || isPlastic) {
                return 'delivery';
            }
            return null;
        },
        previous: () => 'amount'
    },
    [CART_STEP]: {
        next: () => 'payment',
        previous: emptyFunc
    },
    [REDIRECTION_ORDER_STEP]: {
        next: () => 'payment',
        previous: emptyFunc
    },
    [DELIVERY_STEP]: {
        next: ({ isPlastic, isSelfBuy }) => {
            if (isPlastic || !isSelfBuy) {
                return 'delivery';
            }
            return 'shipping';
        },
        previous: () => 'amount'
    },
    [SHIPPING_STEP]: {
        next: emptyFunc,
        previous: () => 'delivery'
    },
    [PRODUCT_DETAIL]: {
        next: ({ isPlastic }) => {
            if (isPlastic) {
                return 'shipping';
            }
            return 'cart';
        },
        previous: emptyFunc,
        canLoad: () => true,
        isReady: () => true,
        smartFlowSkip: () => false
    }
};

export const FlowProvider = ({ children, startingCompletedSteps, flowConfig }) => {
    const [completedSteps, setCompletedSteps] = useState(startingCompletedSteps);
    
    const dispatch = useDispatch();

    const isSingleFaceplate = useSelector(state => hasSingleFaceplateForOneCardType(state));


    const preferredValues = useSelector(state => getPreferredValues(state));
    const isPreferredCardType = preferredValues.cardType !== null && preferredValues.cardType !== undefined;

    const isSingleCardType = useSelector(state => !canAddDigitalItem(state)
        || !canAddPlasticItem(state)
        || isPreferredCardType);
    const isSingleDenomination = useSelector(state => amountStepSkippable(state));

    const isPlastic = useSelector(state => newItemIsPlastic(state));
    const hasAccessories = useSelector(state => newItemIsAccessoriesStepForSelfBuy(state));
    const isSelfBuy = useSelector(state => newItemIsSelfBuy(state));
    const isMessageSkippable = useSelector(state => messageStepSkippable(state));
    const supportsAnyUyo = useSelector(state => !!getSupportedUYO(state).find(value => value));
    
    
    const flowParams = {
        isPlastic,
        hasAccessories,
        isSelfBuy,
        isMessageSkippable,
        isSingleFaceplate,
        isSingleCardType,
        isSingleDenomination,
        supportsAnyUyo
    };

    const showProgramSelection = useSelector(state => getShowProgramSelection(state));
    const canAddPlasticItems = useSelector(state => canAddPlasticItem(state));
    const canAddDigitalItems = useSelector(state => canAddDigitalItem(state));
    const segmentLoadCheckParams = {
        showProgramSelection,
        canAddDigitalItems,
        canAddPlasticItems
    };

    const programsLoaded = useSelector(state => getProgramsLoaded(state));
    const brandCatalogHasLoaded = useSelector(state => brandCatalogIsLoaded(state));
    const hasDigitalFaceplate = useSelector(state => hasDigitalFaceplates(state));
    const hasAFaceplate = useSelector(state => hasFaceplates(state));
    const hasPlasticFaceplate = useSelector(state => hasPlasticFaceplates(state));
    const segmentReadyCheckParams = {
        programsLoaded,
        brandCatalogHasLoaded,
        hasAFaceplate,
        hasDigitalFaceplate,
        hasPlasticFaceplate
    };

    const canSkipProgram = useSelector(state => programStepSkippable(state));
    const canShowPrograms = useSelector(state => getShowProgramSelection(state));
    const preferredCardTypeSelected = useSelector(state => getPreferredCardType(state) !== null);
    const preferredSelfBuy = useSelector(state => getPreferredSelfBuy(state));
    const preferredRecipientName = useSelector(state => getPreferredRecipientName(state));
    const preferredSenderName = useSelector(state => getPreferredSenderName(state));
    const smartFlowSkipParams = {
        programsLoaded,
        canShowPrograms,
        showProgramSelection,
        canSkipProgram,
        isSelfBuy,
        preferredCardTypeSelected,
        canAddDigitalItems,
        canAddPlasticItems,
        preferredSelfBuy,
        preferredRecipientName,
        preferredSenderName
    };

    const setStepCompleted = (stepName, isCompleted = true) => {
        setCompletedSteps({ ...completedSteps, [stepName]: isCompleted });
    };

    
    
    const pushFlowProgressUpdates = (progressUpdates = {}) => {
        dispatch(updateFlowProgress(fromJS(progressUpdates).keySeq()));
    };

    const pushOrReplacePath = (path, shouldPushHistory, overwritingHistoryParams = {}) => {
        if (shouldPushHistory) {
            push({ pathname: path, ...overwritingHistoryParams });
        } else {
            replace({ pathname: path, ...overwritingHistoryParams });
        }
    };

    const canFirePageLoadSegmentEvent = (stepName) => {
        if ((
            flowConfig[stepName].canLoad(segmentLoadCheckParams)
            && flowConfig[stepName].isReady(segmentReadyCheckParams)
            && !flowConfig[stepName].smartFlowSkip(smartFlowSkipParams)
        )) {
            return true;
        }
        return false;
    };

    const firePageLoadSegmentEvent = (stepName) => {
        if (canFirePageLoadSegmentEvent(stepName)) {
            dispatch(changeStep(stepName, null));
        }
    };

    const goToNextStep = (stepName, additionalFlowParams = {}, flowUpdates = []) => {
        const nextStep = flowConfig[stepName].next({ ...flowParams, ...additionalFlowParams });
        const { shouldPushHistory = true, overwritingHistoryParams = {} } = additionalFlowParams;
        
        
        pushFlowProgressUpdates(flowUpdates);
        setStepCompleted(stepName);
        if (nextStep) {
            const route = `/${getBasePath()}/${nextStep}`;
            pushOrReplacePath(route, shouldPushHistory, overwritingHistoryParams);
        }
    };

    const goToPreviousStep = (stepName, additionalFlowParams = {}) => {
        const previous = flowConfig[stepName].previous({ ...flowParams, ...additionalFlowParams });
        if (previous) {
            const route = `/${getBasePath()}/${previous}`;
            push({ pathname: route });
        }
    };

    
    const findFirstIncompleteStep = (stepName, additionalFlowParams) => {
        let currentStep = flowConfig[stepName];
        let currentStepName = stepName;
        while (currentStep) {
            const previous = currentStep.previous({ ...flowParams, ...additionalFlowParams });
            if (completedSteps[previous] && currentStepName !== stepName) {
                return currentStepName;
            }
            if (!previous) {
                return currentStepName;
            }
            currentStep = flowConfig[previous];
            currentStepName = previous;
        }
        
        return '';
    };

    const getSubmitButtonText = (stepName, submitMessageParams = {}) => {
        const currentStep = flowConfig[stepName];
        if (currentStep) {
            const msgKey = currentStep.next(flowParams);
            const submitButtonText = flowStepSubmitMessages[msgKey];
            if (submitButtonText) {
                return submitButtonText.getSubmitMessage(flowParams, submitMessageParams);
            }
        }
        return flowMessages.genericSubmitButtonText;
    };

    return (
        <FlowContext.Provider
            value={{
                goToNextStep,
                goToPreviousStep,
                setStepCompleted,
                findFirstIncompleteStep,
                getSubmitButtonText,
                completedSteps,
                canFirePageLoadSegmentEvent,
                firePageLoadSegmentEvent
            }}
        >
            {children}
        </FlowContext.Provider>
    );
};

FlowProvider.displayName = 'FlowProvider';

FlowProvider.propTypes = {
    children: PropTypes.node.isRequired,
    startingCompletedSteps: PropTypes.object,
    flowConfig: PropTypes.object
};

FlowProvider.defaultProps = {
    startingCompletedSteps: {},
    flowConfig: DEFAULT_FLOW_CONFIG
};

export const useFlow = () => useContext(FlowContext);
