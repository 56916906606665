import { fromJS, List as IList, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';
import { Big } from 'big.js';

import { getAddress, getAddresses } from '../address/addressSelectors';
import {
    ADDRESS_STREET1,
    ADDRESS_FULLNAME,
    ADDRESS_FIRSTNAME,
    ADDRESS_LASTNAME,
    ADDRESS_PHONE,
    getAddressHash
} from '../address/addressModule';
import {
    getMultipleCurrenciesSupported,
    getElectronicCurrencyList,
    getMaxTransactionAmount,
    getPlasticCurrencyList
} from '../catalogs/catalogSelectors';
import {
    faceplateForCode,
    hasDigital as hasDigitalFaceplates,
    hasPlastic as hasPlasticFaceplates
} from '../faceplate/faceplateSelectors';
import {
    getPromoRewardCards,
    hasPromoRewards,
    getPromoLabel,
    getDiscountRewardAmount,
    getShippingPromoData,
    getPromoShippingCost,
    getAccessoriesPromoData,
    isPromoSelfBonus
} from '../promo/promoSelectors';
import { getAccessoryCostByCurrency } from '../accessories/accessoriesSelectors';
import {
    getShippingMethod,
    getItemShippingMethodProfile,
    getShippingMethodProfileId
} from '../shipping/shippingSelectors';
import { getSupportedUYO } from '../uyo/uyoSelectors';
import { getPromoRecipientHash } from '../promo/promoModule';

import {
    IS_PLASTIC_FIELD,
    IS_SHARE_VIA_LINK,
    IS_SELFBUY_FIELD,
    RECIPIENT_PLASTIC_NOTIFICATION,
    ITEM_ACCESSORY,
    RECIPIENT_NAME_FIELD,
    giftCardFieldNames,
    newItemIsPlastic,
    newItemDenomination,
    ITEM_ID,
    MAX_QUANTITY
} from './newItemForm';
import {
    ITEM_DISPLAY_STORE_NAME,
    SHOW_DETAILS_KEY,
    EDIT_DETAILS_KEY,
    EDIT_ADDRESS_KEY,
    EDIT_QUANTITY_KEY,
    ITEM_FOCUS_SHIPPING_SELECT,
    SHIPPING_ADDRESS_CHANGED,
    SHIPPING_METHOD_CHANGED,
    EDIT_FORM_INVALID_KEY
} from './itemDisplayModule';

import { getCartMaxNumPlasticCards } from '../brand/brandSelectors';


export const isPlasticItem = item => item.get(IS_PLASTIC_FIELD);

export const isSharedViaLink = item => item.get(IS_SHARE_VIA_LINK);


export const isPromoItem = item => item && item.has('category') && item.get('category') === 'PRMO';


export const isSelfBuyItem = item => item.get(IS_SELFBUY_FIELD);


export const hasPlasticNotification = item => item.get(RECIPIENT_PLASTIC_NOTIFICATION);


export const getItemRecipientName = item => item.get(RECIPIENT_NAME_FIELD, null);


export const getItemRecipientEmail = item => item.get(giftCardFieldNames.RECIPIENT_EMAIL_FIELD, null);


export const getItemMessage = item => item.get(giftCardFieldNames.MESSAGE_FIELD, null);


export const getItemSenderName = item => item.get(giftCardFieldNames.SENDER_NAME_FIELD, null);



export const getItemId = (item) => {
    if (isPromoItem(item)) {
        const isSelfBonus = isPromoSelfBonus(item);
        const recipientHash = isSelfBonus ? 'selfBonus' : getPromoRecipientHash(item);
        return `${parseFloat(item.get('cardValue'))}-${item.getIn(['faceplate', 'code'])}-${recipientHash}`;
    }
    return item.get(ITEM_ID);
};



export const getItemQuantity = item => (
    item && item.get(giftCardFieldNames.QUANTITY_FIELD, 1)
);


export const getItemCurrency = item => (
    item.get(giftCardFieldNames.CURRENCY_FIELD, 'USD')
);


export const getItemDenomination = item => (
    isPromoItem(item) ? parseFloat(item.get('cardValue')) : parseFloat(item && item.get(giftCardFieldNames.AMOUNT_FIELD))
);


export const getIsNonDenomItem = item => (
    getItemDenomination(item) === 0.01
);


export const getPromoItemDenomination = item => (
    getIsNonDenomItem(item) ? parseInt(item.get('cardValue'), 10) : parseFloat(item.get('cardValue'))
);


export const getItemLabel = item => (
    isPromoItem(item) ? getPromoLabel(item) : ''
);


export const getItemFaceplate = (item, state) => {
    const faceplateData = isPromoItem(item) ? item.get('faceplate') : item.get('faceplateData');
    return faceplateData || faceplateForCode(state, item.get('faceplate'));
};


export const getUyoUrl = item => (
    item.get('uyoImageURL')
);


export const getCardsCount = items => (
    items.reduce((acc, item) => (acc + getItemQuantity(item)), 0)
);


export const getOrderLineCount = (items) => {
    
    
    const itemsFiltered = items.toJS().filter(
        (item, index, itemsArray) => (
            itemsArray.findIndex(itemIndex => (
                itemIndex.id === item.id
            )) === index
        )
    );

    const itemsFilteredList = fromJS(itemsFiltered);

    return itemsFilteredList.reduce((acc, item) => (acc + getItemQuantity(item)), 0);
};


export const sumFaceDenoms = items => (
    items.reduce((acc, item) => (
        new Big(acc).add(new Big(getItemDenomination(item)).times(getItemQuantity(item)))
    ), 0)
);


export const filterPaidItems = items => (
    items.filter(item => !isPromoItem(item))
);


export const filterPromoItems = items => (
    items.filter(item => isPromoItem(item))
);


export const getItemTotalCost = item => (
    new Big(getItemDenomination(item)).times(getItemQuantity(item)) || new Big('0')
);




export const getItems = state => state.get('items', IList());


export const getActiveItems = (state) => {
    const items = getItems(state);
    return items.filterNot(item => item.get('isRemoved') === true);
};


export const getDisplay = state => state.get('itemDisplay');


export const getShippingAddress = (state, item) => (
    item.has(giftCardFieldNames.ADDRESS_ID) ? getAddress(state, item.get(giftCardFieldNames.ADDRESS_ID)) : null
);


export const getShippingCost = (state, item) => {
    const method = getShippingMethod(
        state,
        getItemShippingMethodProfile(item),
        item.get('shippingMethod')
    );
    return parseFloat(method.get('cost'));
};


export const getItemRecipients = createSelector(
    getActiveItems,
    (items) => {
        const recipients = {};
        items.forEach((item) => {
            const itemId = item.get('id');
            const recipientEmail = item.get('recipientEmail');
            const recipientName = item.get('recipientName');
            if (itemId && recipientEmail && recipientName) {
                recipients[itemId] = { email: recipientEmail, recipient_name: recipientName };
            }
        });
        return fromJS(recipients);
    }
);


export const shippingCostCreator = state => (
    (item) => {
        const method = getShippingMethod(
            state,
            getItemShippingMethodProfile(item),
            item.get('shippingMethod')
        );
        return method ? parseFloat(method.get('cost')) : 0.00;
    }
);


export const cartOnlyContainsSelfBuyCards = createSelector(
    getActiveItems,
    (activeItems) => {
        const activeItemsArray = Array.from(activeItems);
        const foundNonSelfBuyCards = activeItemsArray.some(item => item.get('isSelfBuy') === false);

        return !foundNonSelfBuyCards;
    }
);


export const hasSelfBuyItems = createSelector(
    getActiveItems,
    activeItems => (
        Array.from(activeItems).some(item => item.get('isSelfBuy') === true)
    )
);






export const itemHasAccessory = item => !!item.get(ITEM_ACCESSORY);


export const getItemAccessory = item => item.get(ITEM_ACCESSORY, IMap());


export const getItemsWithAccessories = createSelector(
    getActiveItems,
    items => items.filter(itemHasAccessory)
);


export const getItemWithUpdatedPromoAccessoryCosts = createSelector(
    [itemHasAccessory, getItemCurrency, getItemAccessory, item => item],
    (hasAccessory, currency, accessory, item) => (accessoriesPromoData) => {
        if (hasAccessory) {
            const accessoryCost = getAccessoryCostByCurrency(accessory, accessoriesPromoData, currency);
            return item.setIn(['accessory', 'costs', currency, 'cost'], accessoryCost);
        }
        return item;
    }
);


export const getItemAccessoryTotalCost = createSelector(
    [getItemAccessory, getItemCurrency, getItemQuantity],
    (accessory, currency, quantity) => accessoryPromoData => (
        new Big(getAccessoryCostByCurrency(accessory, accessoryPromoData, currency)).times(quantity)
    )
);


export const getItemAccessoriesGrandTotalCost = createSelector(
    getItemsWithAccessories,
    getAccessoriesPromoData,
    (items, accessoryPromoData) => items.reduce(
        (acc, item) => (acc.add(getItemAccessoryTotalCost(item)(accessoryPromoData))), new Big('0')
    )
);


export const getItemsAccessoryCount = createSelector(
    getItemsWithAccessories,
    items => items.reduce((acc, item) => acc + getItemQuantity(item), 0)
);


export const findItemById = (state, id) => (
    state.get('items').find(item => item.get('id') === id)
);


export const getPaidItems = createSelector(
    getActiveItems,
    filterPaidItems
);


export const getPaidItemsCount = createSelector(
    getPaidItems,
    items => items.size
);


export const getPaidCardsCount = createSelector(
    getPaidItems,
    items => getCardsCount(items)
);


export const getFilteredPaidCardsCount = createSelector(
    getPaidItems,
    items => getOrderLineCount(items)
);


export const getDigitalItems = createSelector(
    getActiveItems,
    items => items.filter(item => !isPlasticItem(item))
);


export const getPaidDigitalItems = createSelector(
    getDigitalItems,
    getAccessoriesPromoData,
    (digitalItems, promoData) => (
        filterPaidItems(digitalItems)
            .map(item => getItemWithUpdatedPromoAccessoryCosts(item)(promoData))
    )
);

export const getPaidSharableLinkItems = createSelector(
    getActiveItems,
    items => items.filter(item => isSharedViaLink(item))
);


export const getPaidDigitalCardsCount = createSelector(
    getPaidDigitalItems,
    items => getCardsCount(items)
);


export const getPlasticItems = createSelector(
    getActiveItems,
    items => items.filter(item => isPlasticItem(item))
);


export const getPlasticItemById = createSelector(
    getPlasticItems,
    plasticItem => itemId => plasticItem.find(item => item.toJS().id === itemId)
);


export const getPaidPlasticItems = createSelector(
    getPlasticItems,
    filterPaidItems
);


export const getPaidPlasticCardsCount = createSelector(
    getPaidPlasticItems,
    items => getCardsCount(items)
);




export const hasDigitalItems = createSelector(
    getDigitalItems,
    items => items.size > 0
);


export const hasPlasticItems = createSelector(
    getPlasticItems,
    items => items.size > 0
);


export const countShareViaLinkItems = createSelector(
    getPaidSharableLinkItems,
    items => getCardsCount(items)
);


export const hasPromoItems = state => hasPromoRewards(state);


export const getShowDetails = (state, context, item) => (
    state.getIn([ITEM_DISPLAY_STORE_NAME, item.get('id'), context, SHOW_DETAILS_KEY])
        || false
);


export const getEditDetails = (state, item) => (
    state.getIn([ITEM_DISPLAY_STORE_NAME, item.get('id'), EDIT_DETAILS_KEY], false)
);


export const getEditFormValid = (state, item) => (
    state.getIn([ITEM_DISPLAY_STORE_NAME, item && item.get('id'), EDIT_FORM_INVALID_KEY], false)
);


export const getEditAddress = (state, item) => (
    state.getIn([ITEM_DISPLAY_STORE_NAME, item.get('id'), EDIT_ADDRESS_KEY], false)
);


export const getFocusedShipping = (state, item) => (
    state.getIn([ITEM_DISPLAY_STORE_NAME, item.get('id'), ITEM_FOCUS_SHIPPING_SELECT], false)
);


export const getShippingAddressChanged = (state, item) => (
    state.getIn([ITEM_DISPLAY_STORE_NAME, item.get('id'), SHIPPING_ADDRESS_CHANGED], false)
);


export const getShippingMethodChanged = (state, item) => (
    state.getIn([ITEM_DISPLAY_STORE_NAME, item.get('id'), SHIPPING_METHOD_CHANGED], false)
);


const isEditingBy = editingBy => state => (
    state.get(ITEM_DISPLAY_STORE_NAME).some(editingBy)
);


export const isEditingDetails = isEditingBy(itemDisplay => itemDisplay.get(EDIT_DETAILS_KEY));


export const isEditingAddress = isEditingBy(itemDisplay => itemDisplay.get(EDIT_ADDRESS_KEY));


export const isEditingQuantity = isEditingBy(itemDisplay => itemDisplay.get(EDIT_QUANTITY_KEY));


export const itemsAreEditing = state => (
    isEditingDetails(state) || isEditingAddress(state)
);






export const groupByShippingMethodAndAddress = createSelector(
    getPlasticItems,
    items => items.groupBy(
        item => `${item.get(giftCardFieldNames.ADDRESS_ID)}::${item.get('shippingMethod')}`
    )
);


export const createShippingGroup = (items, shipMethod, address, promoShippingMethods) => {
    const shippingCost = getPromoShippingCost(items, shipMethod, promoShippingMethods);
    return fromJS({
        items,
        address,
        shippingMethod: shipMethod,
        sumFaceValue: parseFloat(sumFaceDenoms(items).toFixed(2)),
        shippingCost
    });
};


export const shippingGroupsForAddressAndMethod = (state, items) => {
    const promoShippingMethods = getShippingPromoData(state);
    const quantity = getCardsCount(items);
    const methodID = items.get(0).get(giftCardFieldNames.SHIPPING_METHOD_FIELD);
    const addressID = items.get(0).get(giftCardFieldNames.ADDRESS_ID);
    const currency = items.get(0).get(giftCardFieldNames.CURRENCY_FIELD);
    const address = getAddress(state, addressID);

    const hasAccessory = items.some(itemHasAccessory);
    const promoAccessories = getAccessoriesPromoData(state);
    const updatedItems = items.map(item => getItemWithUpdatedPromoAccessoryCosts(item)(promoAccessories));

    const profile = getShippingMethodProfileId(addressID, quantity, currency, hasAccessory);
    const qtyMethod = getShippingMethod(state, profile, methodID);

    
    if (qtyMethod) {
        return fromJS([createShippingGroup(updatedItems, qtyMethod, address, promoShippingMethods)]);
    }

    
    
    
    const singleProfile = getShippingMethodProfileId(addressID, 1, currency, hasAccessory);
    const singleMethod = getShippingMethod(state, singleProfile, methodID);
    return fromJS([createShippingGroup(updatedItems, singleMethod, address, promoShippingMethods)]);
};


export const shippingGroups = state => (
    groupByShippingMethodAndAddress(state).map(
        items => shippingGroupsForAddressAndMethod(state, items)
    ).reduce(
        (allGroups, groups) => allGroups.concat(groups), IList()
    )
);


export const getShippingGroups = createSelector(
    shippingGroups,
    groups => groups
);


export const getShippingGroupByItem = createSelector(
    shippingGroups,
    groups => item => groups.find(
        group => group.toJS().items.find(
            groupItem => groupItem.id === item.toJS().id
        )
    )
);


export const getShippingTotal = createSelector(
    getShippingGroups,
    groups => groups.reduce((acc, group) => (acc + parseFloat(group.get('shippingCost'))), 0)
);


export const getReusableShippingAddresses = createSelector(
    getPlasticItems,
    getAddresses,
    (items, addresses) => items
        .map(item => addresses.get(item.get(giftCardFieldNames.ADDRESS_ID)))
        .map(address => address.set(ADDRESS_FULLNAME, '')
            .set(ADDRESS_FIRSTNAME, '')
            .set(ADDRESS_LASTNAME, '')
            .set(ADDRESS_PHONE, ''))
        .groupBy(address => getAddressHash(address))
        .map(similarAddresses => similarAddresses.first())
        .sortBy(address => address.get(ADDRESS_STREET1))
);






export const getDigitalTotal = createSelector(
    getPaidDigitalItems,
    items => sumFaceDenoms(items)
);


export const getPlasticTotal = createSelector(
    getPaidPlasticItems,
    items => sumFaceDenoms(items)
);


export const getUYOPQuantity = createSelector(
    getPaidPlasticItems,
    (items) => {
        const uyopCards = items.filter(item => item.has('uyoImageURL'));
        return uyopCards.reduce((totalQuantity, card) => totalQuantity + card.get('quantity', 0), 0);
    }
);


export const getSubTotal = createSelector(
    [getDigitalTotal, getPlasticTotal],
    
    
    (digital, plastic) => new Big(digital).add(plastic)
);


export const getTotalCostMinusFees = createSelector(
    [getSubTotal, getShippingTotal, getItemAccessoriesGrandTotalCost],
    (sub, ship, accessoriesTotal) => new Big(sub).add(ship).add(accessoriesTotal)
);


export const getTotalCostIncludingNewItems = createSelector(
    [getSubTotal, getShippingTotal, newItemDenomination],
    (sub, ship, newItemTotal) => new Big(sub).add(ship).add(newItemTotal)
);


export const getGrandTotalCostMinusFees = createSelector(
    [getTotalCostMinusFees, getDiscountRewardAmount],
    (totalCost, discount) => new Big(totalCost).add(discount)
);


export const getIsCartMaxed = createSelector(
    [getSubTotal, getMaxTransactionAmount, getDiscountRewardAmount],
    (subTotal, maxTrans, discount) => (subTotal.add(discount).gte(maxTrans))
);


export const getCurrencyCodes = createSelector(
    getPaidItems,
    items => items.map(item => getItemCurrency(item)).toSet()
);


export const getCartProgramCodes = createSelector(
    getActiveItems,
    items => items.map(item => item.get(giftCardFieldNames.PROGRAM_CODE))
        .filter(code => code && code.length > 0)
        .toSet()
);


export const getPromoItems = createSelector(
    getPromoRewardCards,
    getCurrencyCodes,
    (rewards, currencyCodes) => {
        if (rewards.size <= 0) return IList();

        
        const currencyCode = currencyCodes.first();
        const promoItems = IMap().withMutations((itemsMap) => {
            
            
            rewards.forEach((reward) => {
                
                const rewardKey = getItemId(reward);

                if (!itemsMap.has(rewardKey)) {
                    
                    const missingProperties = {
                        id: rewardKey,
                        quantity: 1,
                        isEditable: false
                    };
                    itemsMap.set(rewardKey, reward.merge(missingProperties));
                } else {
                    itemsMap.updateIn([rewardKey, 'quantity'], quantity => quantity + 1);
                }
                if (!itemsMap.getIn([rewardKey, 'currencyCode'])) {
                    
                    
                    itemsMap.setIn([rewardKey, 'currencyCode'], currencyCode);
                }
            });
            return itemsMap;
        });

        return promoItems.toList();
    }
);


export const getPromoLineItems = createSelector(
    getPromoItems,
    (rewards) => {
        const promoItems = IMap().withMutations((itemsMap) => {
            rewards.forEach((reward) => {
                
                const rewardKey = `${parseFloat(reward.get('cardValue'))}-${reward.getIn(['faceplate', 'code'])}`;

                if (!itemsMap.has(rewardKey)) {
                    itemsMap.set(rewardKey, reward);
                } else {
                    itemsMap.updateIn([rewardKey, 'quantity'], quantity => quantity + reward.get('quantity'));
                }
            });
            return itemsMap;
        });

        return promoItems.toList();
    }
);


export const getItemCurrencyList = createSelector(
    newItemIsPlastic,
    getPlasticCurrencyList,
    getElectronicCurrencyList,
    (isPlastic, plasticCurrencyList, electronicCurrencyList) => (
        isPlastic ? plasticCurrencyList : electronicCurrencyList)
);


export const showCurrencyPicker = createSelector(
    getMultipleCurrenciesSupported,
    getItemCurrencyList,
    (supportsMultipleCurrencies, itemCurrencyList) => (
        supportsMultipleCurrencies && itemCurrencyList.size > 1)
);


export const canAddDigitalItem = createSelector(
    hasDigitalFaceplates,
    getElectronicCurrencyList,
    getCurrencyCodes,
    (hasDigital, electronicCurrencyList, currencyCodes) => {
        if (currencyCodes.size > 0) {
            return hasDigital && electronicCurrencyList.includes(currencyCodes.first());
        }
        return hasDigital && electronicCurrencyList.size > 0;
    }
);




export const canAddPlasticItem = createSelector(
    hasPlasticFaceplates,
    getPlasticCurrencyList,
    getCurrencyCodes,
    (hasPlastic, plasticCurrencyList, currencyCodes) => {
        if (currencyCodes.size > 0) {
            return hasPlastic && plasticCurrencyList.includes(currencyCodes.first());
        }
        return hasPlastic && plasticCurrencyList.size > 0;
    }
);


export const canAddPlasticUYOItem = createSelector(
    getSupportedUYO,
    getPlasticCurrencyList,
    getCurrencyCodes,
    (supportedUYO, plasticCurrencyList, currencyCodes) => {
        if (currencyCodes.size > 0) {
            return supportedUYO.get('supportsUYOPlasticPhoto') && plasticCurrencyList.includes(currencyCodes.first());
        }
        return supportedUYO.get('supportsUYOPlasticPhoto') && plasticCurrencyList.size > 0;
    }
);


export const canAddDigitalUYOItem = createSelector(
    getSupportedUYO,
    getElectronicCurrencyList,
    getCurrencyCodes,
    (supportedUYO, electronicCurrencyList, currencyCodes) => {
        if (currencyCodes.size > 0) {
            return supportedUYO.get('supportsUYOEgcPhoto') && electronicCurrencyList.includes(currencyCodes.first());
        }
        return supportedUYO.get('supportsUYOEgcPhoto') && electronicCurrencyList.size > 0;
    }
);


export const canUploadUYOPhoto = createSelector(
    canAddPlasticUYOItem,
    canAddDigitalUYOItem,
    (canAddPlastic, canAddDigital) => (
        canAddPlastic || canAddDigital
    )
);

export const getRemainingPlasticCardsAllowed = createSelector(
    getCartMaxNumPlasticCards,
    getPaidPlasticCardsCount,
    (cartMaxNumPlasticCards, paidPlasticCardsCount) => {
        const cartMaxPlastic = cartMaxNumPlasticCards || MAX_QUANTITY;
        const remainingPlasticCardsAllowed = cartMaxPlastic - paidPlasticCardsCount;
        if (remainingPlasticCardsAllowed < 0) {
            return 0;
        }
        return remainingPlasticCardsAllowed;
    }
);


export const isBundleItem = (state, itemId) => {
    const items = getItems(state);
    let bundleItemLength = 0;

    const isItemInBundle = items.toJS().some((item) => {
        if (item.id === itemId) {
            bundleItemLength += 1;
        }
        if (bundleItemLength > 1) {
            return true;
        }
        return false;
    });

    return isItemInBundle;
};


export const getBundleValue = (state, itemId) => {
    const items = getItems(state);
    let bundleCost = 0;

    items.toJS().forEach((item) => {
        if (item.id === itemId) {
            bundleCost += parseFloat(item.amount);
        }
    });

    return bundleCost.toString();
};
