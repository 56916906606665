import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { List as IList } from 'immutable';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';

import { getCapiSessionID } from 'capi/redux/immutable';
import { loadTrackingImagePixel } from '../validate/isProfane';
import { brandCatalogIsLoaded, getProfanityDetectionEnabled, getSelfBuyEnabled } from '../brand/brandSelectors';
import { primitiveMessages } from '../primitive/primitiveMessages';

import recipientMessages from './recipientMessages';
import RecipientNameForm from './RecipientNameForm';
import { shouldDisplayFaceplate } from './recipientStepSelectors';
import { newItemIsPlastic, newItemIsSelfBuy } from '../item/newItemForm';
import { useFlow } from '../routing/FlowProvider';
import {
    hasDigital as hasDigitalFaceplates,
    hasPlastic as hasPlasticFaceplates
} from '../faceplate/faceplateSelectors';
import LoadingIndicator from '../primitive/LoadingIndicator';
import { getPreferredRecipientName, getPreferredSelfBuy, getPreferredSenderName } from '../routing/flowSelectors';
import TranslatedPageTitle from '../header/TranslatedPageTitle';

export const RecipientStep = ({
    intl,
    stepName
}) => {
    const { goToNextStep, getSubmitButtonText } = useFlow();
    const [recipientSubmitButtonText, setRecipientSubmitButtonText] = useState(getSubmitButtonText(stepName));
    
    const isSelfBuyEnabled = useSelector(state => getSelfBuyEnabled(state));
    const isSelfBuy = useSelector(state => newItemIsSelfBuy(state));
    const isPlastic = useSelector(state => newItemIsPlastic(state));
    const { faceplate, showFaceplate, faceplates = IList() } = useSelector(state => shouldDisplayFaceplate(state));
    const catalogLoaded = useSelector(state => brandCatalogIsLoaded(state));
    const hasDigital = useSelector(state => hasDigitalFaceplates(state));
    const hasPlastic = useSelector(state => hasPlasticFaceplates(state));
    const isProfanityDetectionEnabled = useSelector(state => getProfanityDetectionEnabled(state));
    const capiSessionID = useSelector(state => getCapiSessionID(state));

    
    const preferredSelfBuy = useSelector(state => getPreferredSelfBuy(state));
    const preferredRecipientName = useSelector(state => getPreferredRecipientName(state));
    const preferrredSenderName = useSelector(state => getPreferredSenderName(state));
    const canSkip = preferredSelfBuy || (preferredSelfBuy === false && preferredRecipientName && preferrredSenderName);
    const isReady = catalogLoaded && (hasDigital || hasPlastic);

    const recipientTitle = faceplates.size > 1 ? recipientMessages.pluralTitle : recipientMessages.title;

    
    const [profanityFlag, setProfanityFlag] = useState(null);
    useEffect(() => {
        loadTrackingImagePixel(profanityFlag, capiSessionID);
    }, [profanityFlag]);

    useEffect(() => {
        if (isReady) {
            setRecipientSubmitButtonText(getSubmitButtonText(stepName, { isPlastic, isSelfBuy }));
            if (canSkip) {
                goToNextStep(stepName, { shouldPushHistory: false });
            }
        }
    }, [isReady, isSelfBuy]);

    const containerProps = {
        heading: intl.formatMessage(recipientTitle),
        HeadingElement: 'h1',
        showRequiredMsg: true,
        requiredMsg: intl.formatMessage(primitiveMessages.requiredInstructionText)
    };
    const formProps = {
        submitAction: (values) => {
            goToNextStep(stepName, {}, values);
        },
        cashbotName: 'recipient',
        isSelfBuyEnabled,
        isSelfBuy,
        faceplate,
        faceplates,
        showFaceplate,
        isProfanityDetectionEnabled,
        profanityFlag,
        setProfanityFlag,
        capiSessionID,
        submitButtonMessage: recipientSubmitButtonText
    };

    if (!isReady) {
        return (
            <React.Fragment>
                <LoadingIndicator />
            </React.Fragment>
        );
    }

    return (
        <Container {...containerProps}>
            <TranslatedPageTitle pageTitle={recipientTitle} />
            <RecipientNameForm {...formProps} />
        </Container>
    );
};

RecipientStep.displayName = 'RecipientStep';

RecipientStep.propTypes = {
    stepName: PropTypes.string.isRequired,
    
    intl: intlShape.isRequired
};

export default injectIntl(RecipientStep);
