import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { intlShape } from 'react-intl';
import { CheckboxToggle } from 'cstar-react-primitives/lib/redux-form/microFields/CheckboxToggle';
import { Transition } from 'cstar-react-primitives/lib/redux-form/animate/Transition';

import { RECIPIENT_PLASTIC_NOTIFICATION } from '../item/newItemForm';

import deliveryMessages from './deliveryMessages';
import RecipientEmailField from './RecipientEmailField';
import { REQUIRED, OPTIONAL } from './PhysicalDeliveryFormConstants';

export const RecipientNotification = ({
    hasPlasticNotification,
    requireRecipientEmailForPhysicalGift,
    cashbotName,
    recipientName,
    isPlastic,
    intl,
    handleClick,
    cartEdit
}) => {
    const emailProps = {
        intl,
        recipientName,
        isPlastic,
        cashbotName,
        requireRecipientEmailForPhysicalGift,
        spellCheckEmailPromptEnabled: true,
        cartEdit
    };
    const toggleFieldProps = {
        name: RECIPIENT_PLASTIC_NOTIFICATION,
        label: intl.formatMessage(deliveryMessages.recipientNotificationLabel, { name: recipientName }),
        component: CheckboxToggle,
        cashbotName,
        onChange: handleClick
    };

    if (requireRecipientEmailForPhysicalGift === REQUIRED) {
        return <RecipientEmailField {...emailProps} />;
    }
    return (
        <div>
            <Field {...toggleFieldProps} />
            <Transition>
                { hasPlasticNotification && <RecipientEmailField {...emailProps} /> }
            </Transition>
        </div>
    );
};

RecipientNotification.defaultProps = {
    hasPlasticNotification: false,
    requireRecipientEmailForPhysicalGift: OPTIONAL,
    isPlastic: false,
    handleClick: () => {},
    cartEdit: false
};

RecipientNotification.propTypes = {
    hasPlasticNotification: PropTypes.bool,
    requireRecipientEmailForPhysicalGift: PropTypes.string,
    cashbotName: PropTypes.string.isRequired,
    recipientName: PropTypes.string.isRequired,
    isPlastic: PropTypes.bool,
    intl: intlShape.isRequired,
    handleClick: PropTypes.func,
    cartEdit: PropTypes.bool
};

export default RecipientNotification;
