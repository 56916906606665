import { Map as IMap } from 'immutable';
import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form/immutable';
import Big from 'big.js';

import {
    getApplePayEnabled,
    getBrandCode,
    getCreditCardValidationData,
    getMasterpassEnabled,
    isPaypalEnabled
} from '../brand/brandSelectors';
import { guessCreditCardType } from '../creditcard/creditCardTypeDeduction';
import {
    getCurrencyCodes,
    getTotalCostIncludingNewItems,
    getTotalCostMinusFees
} from '../item/itemSelectors';
import { getSenderOptIn, getSenderSMSDelivery } from '../sender/senderSelectors';
import { getActivePromoCode, getDiscountRewardAmount } from '../promo/promoSelectors';
import { addressFieldNames } from '../address/addressModule';
import { LOYALTY_FIELD } from '../loyalty/loyaltyModule';
import {
    getIntlCurrencyCode,
    getIntlLocale
} from '../intl/intlSelectors';

import { PAYMENT_FORM_NAME, SENDER_EMAIL_FIELD } from './paymentConstants';



export const getPayment = state => state.get('payment');


export const paymentFormValues = getFormValues(PAYMENT_FORM_NAME);


export const paymentStatus = createSelector(
    getPayment,
    payment => payment.get('status')
);

export const getShareViaLinkPayload = createSelector(
    getPayment,
    payment => payment.get('sharableLinkItems')
);


export const paymentResponse = createSelector(
    getPayment,
    payment => payment.get('response')
);


export const numOrderAttempts = createSelector(
    getPayment,
    payment => payment.get('numAttempts')
);


export const orderResponse = createSelector(
    getPayment,
    payment => payment.get('response')
);


export const getConsumerFees = createSelector(
    getPayment,
    payment => payment.get('fees')
);


export const getGrandTotal = createSelector(
    getPayment,
    payment => payment.get('grandTotal')
);


export const getUyopFeesTotal = createSelector(
    getPayment,
    payment => payment.get('uyopFeesTotal', new Big(0))
);


export const getActivationFeesTotal = createSelector(
    getPayment,
    payment => payment.get('activationFeesTotal', new Big(0))
);


export const getActivationFeesCount = createSelector(
    getPayment,
    payment => payment.get('activationFeesCount')
);


export const getConfirmationUrlParams = createSelector(
    getPayment,
    payment => payment.get('paymentUrlParams')
);


export const creditCardType = createSelector(
    paymentFormValues,
    getCreditCardValidationData,
    (ccValues, validationData) => {
        if (ccValues) {
            return guessCreditCardType(ccValues.get('creditCard'), validationData);
        }
        return null;
    }
);


export const creditCardDetails = createSelector(
    [paymentFormValues, creditCardType],
    (ccValues, ccType) => ccValues.merge({ type: ccType, currency: 'USD' })
);


export const paymentFormCountry = createSelector(
    paymentFormValues,
    values => (values ? values.get(addressFieldNames.ADDRESS_COUNTRY, 'US') : 'US')
);


export const getCartCurrencyCode = createSelector(
    [
        getCurrencyCodes,
        getIntlCurrencyCode
    ],
    (cartCurrencyCodes, intlCurrencyCode) => (
        cartCurrencyCodes.size === 1 ? cartCurrencyCodes.first() : intlCurrencyCode
    )
);


export const getLoyaltyNumber = createSelector(
    paymentFormValues,
    paymentForm => (paymentForm ? paymentForm.get(LOYALTY_FIELD) : '')
);


export const paymentSenderEmail = createSelector(
    paymentFormValues,
    values => (values ? values.get(SENDER_EMAIL_FIELD, '') : '')
);


export const getCheckoutId = createSelector(
    getPayment,
    payment => payment.get('checkoutId')
);


export const getMerchantId = createSelector(
    getPayment,
    payment => payment.get('merchantId')
);



export const getCartAmount = createSelector(
    getPayment,
    payment => payment.get('cartAmount')
);


export const getCountryCode = createSelector(
    getPayment,
    payment => payment.get('countryCode')
);


export const getInitiateConfigData = createSelector(
    [getCheckoutId, getMerchantId, getCartAmount, paymentStatus, getCountryCode],
    (checkoutId, merchantId, grandTotal, status, countryCode) => (
        IMap({
            checkoutId,
            merchantId,
            grandTotal,
            status,
            countryCode
        })
    )
);


export const orderMetaData = createSelector(
    [
        getBrandCode,
        getSenderOptIn,
        getGrandTotal,
        getCartCurrencyCode,
        getActivePromoCode,
        getIntlLocale,
        getLoyaltyNumber,
        getDiscountRewardAmount,
        getSenderSMSDelivery,
        getCheckoutId,
        getCountryCode
    ],
    (brandCode, senderOptIn, amount, currencyCode, promoCode, locale,
        loyaltyNumber, discountAmount, senderSMSDelivery, checkoutId, countryCode) => (
        IMap({
            brandCode,
            senderOptIn,
            amount,
            currencyCode,
            promoCode,
            locale,
            loyaltyNumber,
            discountAmount,
            senderSMSDelivery,
            checkoutId,
            countryCode
        })
    )
);


export const cartOrderMetaData = createSelector(
    [
        getBrandCode,
        getSenderOptIn,
        getTotalCostMinusFees,
        getCartCurrencyCode,
        getActivePromoCode,
        getIntlLocale,
        getLoyaltyNumber
    ],
    (brandCode, senderOptIn, amount, currencyCode, promoCode, locale, loyaltyNumber) => (
        IMap({
            brandCode, senderOptIn, amount, currencyCode, promoCode, locale, loyaltyNumber
        })
    )
);

export const precartOrderMetaData = createSelector(
    [
        getBrandCode,
        getSenderOptIn,
        getTotalCostIncludingNewItems,
        getCartCurrencyCode,
        getActivePromoCode,
        getIntlLocale,
        getLoyaltyNumber
    ],
    (brandCode, senderOptIn, amount, currencyCode, promoCode, locale, loyaltyNumber) => (
        IMap({
            brandCode, senderOptIn, amount, currencyCode, promoCode, locale, loyaltyNumber
        })
    )
);

export const getSupportedPaymentTypes = state => getCreditCardValidationData(state)
    .map(cc => cc.get('name'))
    .update(ccs => (isPaypalEnabled(state) ? ccs.set('paypal', 'PayPal') : ccs))
    .update(ccs => (getApplePayEnabled(state) ? ccs.set('applepay', 'Apple Pay') : ccs))
    .update(ccs => (getMasterpassEnabled(state) ? ccs.set('masterpass', 'Masterpass') : ccs))
    .join(', ');
