import {
    call,
    put,
    select,
    takeLatest
} from 'redux-saga/effects';

import { logError } from '../utils/errorUtils';
import {
    getPaypalClientID
} from '../brand/brandSelectors';
import { getPaypalLocaleCode } from './paypalSelectors';
import {
    importPayPalLocaleMap,
    loadPaypal,
    paypalLoaded,
    paypalAuthFailed,
    START_PAYPAL_LOAD
} from './paypalModule';
import { trackPaymentPerformance } from '../payment/paymentModule';


export function* paypalLoaderSaga() {
    const payPalLocaleMap = (yield call(importPayPalLocaleMap)).default;
    const localeCode = (yield select(getPaypalLocaleCode))(payPalLocaleMap);
    const paypalId = yield select(getPaypalClientID);
    try {
        yield put(trackPaymentPerformance('paypal_sdk_loading:started'));
        yield call(loadPaypal, paypalId, localeCode);
    } catch (event) {
        logError('Error loading PayPal SDK', { paypalId });
        yield put(paypalAuthFailed());
    }
    yield put(trackPaymentPerformance('paypal_sdk_loading:completed'));
    yield put(paypalLoaded());
}

export function* watchPaypalSagas() {
    yield takeLatest(START_PAYPAL_LOAD, paypalLoaderSaga);
}
