import { List as IList } from 'immutable';

import { logError } from '../utils/errorUtils';
import cartMessages from '../cart/cartMessages';
import flowMessages from './flowMessages';
import { getPathStepConfig } from './flowSelectors';



export const BASE_PATH = 'store';
export const SELF_BUY_PATH = 'self';
export const GIFT_BUY_PATH = 'gift';
export const DIGITAL_PATH = 'egc';
export const PHYSICAL_PATH = 'pgc';
export const PAYMENT_PATH = 'pay-method';
export const ABOUT_PATHS = {
    TERMS_AND_CONDITIONS: 'terms_and_conditions',
    FAQ: 'faq',
    PRIVACY_POLICY: 'privacy_policy'
};
export const CARD_TYPE_PATTERN = new RegExp(`\/(${DIGITAL_PATH}|${PHYSICAL_PATH})`); 
export const UYO_STEPS = {
    INIT: 'init',
    SELECT: 'select',
    REVIEW: 'review'
};
export const APP_ERROR_TYPES = {
    NO_RETRY: 'no-retry',
    REFRESH: 'refresh'
};
export const FLOW_MESSAGES = {
    PRE_CART_SKIPPED: 'pre-cart skipped'
};


export const getBasePath = () => BASE_PATH;


export const getDesignPath = () => `/${getBasePath()}/design`;


export const getUYOPath = () => `/${getBasePath()}/uyo`;


export const getCartPath = () => `/${getBasePath()}/cart`;


export const getPaymentMethodsPath = () => `/${getBasePath()}/${PAYMENT_PATH}`;


export const getConfirmationPath = () => `/${getBasePath()}/confirmation`;


export const getCustomerSupportPath = () => '/about/customer_support';


export const getPrivacyPolicyPath = () => `/${getBasePath()}/about/${ABOUT_PATHS.PRIVACY_POLICY}`;


export const getFAQPath = () => `/${getBasePath()}/about/${ABOUT_PATHS.FAQ}`;


export const getTermsAndConditionsPath = () => `/${getBasePath()}/about/${ABOUT_PATHS.TERMS_AND_CONDITIONS}`;


export const getErrorNoRetryPath = () => `/${getBasePath()}/error-no-retry`;


export const getSoldOutPath = () => `/${getBasePath()}/sold-out`;


export const getMessagePath = () => `/${getBasePath()}/message`;


export const getDeliveryPath = () => `/${getBasePath()}/delivery`;


export const buildConditionalFlow = (subFlowConfig, branchFlags) => {
    if (!subFlowConfig || !IList.isList(subFlowConfig) || subFlowConfig.isEmpty()) {
        throw new Error('Improperly configured flow!  Argument subFlowConfig must be an Immutable List of steps!');
    }
    return subFlowConfig
        .map((nextNode) => {
            if (typeof nextNode === 'string') {
                return nextNode;
            }

            if (typeof nextNode === 'function') {
                return buildConditionalFlow(nextNode(branchFlags), branchFlags);
            }

            throw new Error('Improperly configured flow!  All steps must either be of type string or function!');
        })
        .flatten(1);
};


export const getNextPath = (subFlowConfig, currentPath, branchFlags) => {
    try {
        const flow = buildConditionalFlow(subFlowConfig, branchFlags);
        const currentIndex = flow.indexOf(currentPath);

        
        if (currentIndex === flow.size - 1) {
            return null;
        }

        if (currentIndex > -1) {
            return flow.get(currentIndex + 1);
        }

        
        return flow.first();
    } catch (error) {
        logError(error);
        throw error;
    }
};


const getSubmitButtonTextCallback = (nextPath, nextPathConfig, branchFlags, flow) => {
    if (nextPath && nextPathConfig) {
        const getSubmitMessage = nextPathConfig.get('getSubmitMessage');
        if (getSubmitMessage) {
            return (props = {}) => getSubmitMessage({ ...branchFlags, ...props });
        }
    }

    if (nextPath === null && flow === 'buy') {
        
        
        return () => cartMessages.submitButtonText;
    }

    
    
    return () => flowMessages.genericSubmitButtonText;
};


export const getNextPathSubmitMessage = (state, flow, subFlowConfig, currentPath, branchFlags) => {
    let nextPath = currentPath;
    let canSkipNextPath;
    let nextPathConfig;

    do {
        try {
            nextPath = getNextPath(subFlowConfig, nextPath, branchFlags);
            nextPathConfig = getPathStepConfig(state)(nextPath);
            canSkipNextPath = (
                nextPathConfig
                && nextPathConfig.get('canSkip')
                && nextPathConfig.get('canSkip')(state));
        } catch (e) {
            logError(e, { startingPath: currentPath, flow, branchFlags });
            return () => flowMessages.genericSubmitButtonText;
        }
    } while (nextPath !== null && canSkipNextPath === true);

    return getSubmitButtonTextCallback(nextPath, nextPathConfig, branchFlags, flow);
};


export const isAmountStep = path => path === 'amount';


export const isCardTypeStep = path => path === 'type';


export const isUYOStep = path => !!(path && path.startsWith && path.startsWith('uyo'));

export const getLocationPathname = () => window.location.pathname;
