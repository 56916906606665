import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fromJS } from 'immutable';
import { injectIntl, intlShape } from 'react-intl';
import form from 'redux-form/lib/Form';
import { Container } from 'cstar-react-primitives/lib/redux-form/layout/Container';
import PropTypes from 'prop-types';
import OptionsSection from './OptionsSection';
import RecipientDetails from './RecipientDetails';
import { useFlow } from '../routing/FlowProvider';
import {
    brandCatalogIsLoaded,
    getBrandCode,
    getRequireRecipientEmailForPhysicalGift
} from '../brand/brandSelectors';
import {
    EMAIL_FIELD_SELECTED,
    giftCardFieldNames, IS_SHARE_VIA_LINK,
    newItemCountry,
    newItemHasRecipientPlasticNotification, newItemIsPlastic,
    newItemRecipientName,
    newItemReduxForm
} from '../item/newItemForm';

import validate from '../recipient/validate';
import AddressFields from '../addressFields/AddressFields';
import { ADDRESS_CONTEXT } from '../addressFields/addressFieldsModule';
import { changeValue, setDefaultFieldValues } from '../item/newItemFormModule';
import { submitItem } from '../bundles/bundlesUtils';
import { getActiveProgramCatalogs, getActiveProgramCode } from '../program/programSelectors';
import { getDefaultFaceplateForProgram } from '../program/programListSelectors';
import { parseCartViewed } from '../segment/segmentSelectors';
import RecipientNotification from '../delivery/RecipientNotification';
import deliveryMessages from '../delivery/deliveryMessages';
import FormButton from '../primitive/buttons/FormButton';
import cartMessages from '../cart/cartMessages';
import { getShippingMethods } from '../shipping/shippingModule';
import LoadingIndicator from '../primitive/LoadingIndicator';
import { hasFaceplates } from '../faceplate/faceplateSelectors';


const ProductDetail = ({
    intl, addressContext, countryCode, handleSubmit,
    stepName, activeProgram,
    activeCatalogs,
    cartViewedSegment,
    getFaceplates,
    brandCode, hasPlasticNotification, requireRecipientEmailForPhysicalGift, recipientName,
    anyTouched, invalid, submitFailed, getSubmitMessage
}) => {
    const isPlastic = useSelector(state => newItemIsPlastic(state));
    const dispatch = useDispatch();

    const [selectedDelivery, setSelectedDelivery] = useState('egift');
    const [selectedEmail, setSelectedEmail] = useState('email');

    const handleDeliveryChange = (event) => {
        setSelectedDelivery(event.target.value);
        
        if (event.target.value !== 'egift') {
            dispatch(changeValue(giftCardFieldNames.IS_PLASTIC_FIELD, true));
            setSelectedEmail('');
        } else {
            dispatch(changeValue(giftCardFieldNames.IS_PLASTIC_FIELD, false));
            setSelectedEmail('email');
        }
    };

    const handleSelectedEmail = (event) => {
        setSelectedEmail(event.target.value);
    };

    const { goToNextStep } = useFlow();

    const submitAction = (submitValues) => {
        const plastic = submitValues.get('isPlastic');
        const finalselfbuy = submitValues.get('isSelfBuy');
        if (finalselfbuy) {
            dispatch(changeValue(giftCardFieldNames.IS_SHARE_VIA_LINK, false));
            dispatch(changeValue(giftCardFieldNames.EMAIL_FIELD_SELECTED, true));
        }
        if (!plastic) {
            submitItem(
                dispatch,
                submitValues,
                activeProgram,
                activeCatalogs,
                cartViewedSegment,
                getFaceplates,
                brandCode
            );
        }
        if (plastic) {
            dispatch(changeValue(giftCardFieldNames.IS_SHARE_VIA_LINK, false));
            dispatch(changeValue(giftCardFieldNames.EMAIL_FIELD_SELECTED, false));
            const mergedValues = submitValues.merge({
                hasAccessory: !!submitValues.get(giftCardFieldNames.ITEM_ACCESSORY, false)
            });
            dispatch(getShippingMethods(mergedValues, true));
        }
        goToNextStep(stepName, {}, submitValues);
    };

    const addressFieldProps = {
        addressContext,
        countryCode,
        cashbotName: 'delivery',
        doFocus: false,
        form,
        useAddressAutocomplete: true
    };

    const recipientNotificationProps = {
        hasPlasticNotification,
        requireRecipientEmailForPhysicalGift,
        cashbotName: 'delivery',
        recipientName,
        isPlastic: true,
        intl
    };

    const buttonProps = {
        btnText: intl.formatMessage(getSubmitMessage()),
        anyTouched,
        invalid,
        submitFailed,
        cashbotName: 'delivery',
        formName: form
    };

    const pageHeading = deliveryMessages.headerPlastic;
    const containerProps = {
        heading: intl.formatMessage(pageHeading),
        HeadingElement: 'h1'
    };

    useEffect(() => {
        let isSharableLinkSelected = false;
        if (selectedEmail === 'link') {
            isSharableLinkSelected = true;
        }
        const updatedValues = fromJS({
            [IS_SHARE_VIA_LINK]: isSharableLinkSelected,
            [EMAIL_FIELD_SELECTED]: !isSharableLinkSelected
        });
        dispatch(setDefaultFieldValues(updatedValues, true));
    }, [selectedEmail]);

    const isReady = useSelector(state => hasFaceplates(state) && brandCatalogIsLoaded(state));
    if (!isReady) {
        return <LoadingIndicator />;
    }

    return (
        <div className="transperent-box">
            <form method="POST" onSubmit={handleSubmit(submitAction)} noValidate>
                <OptionsSection
                    selectedDelivery={selectedDelivery}
                    handleDeliveryChange={handleDeliveryChange}
                    isPlastic={isPlastic}
                />
                <RecipientDetails
                    isPlastic={isPlastic}
                    selectedDelivery={selectedDelivery}
                    selectedEmail={selectedEmail}
                    handleSelectedEmail={handleSelectedEmail}
                />
                {isPlastic && (
                    <div className='container'>
                        <Container {...containerProps} />
                        <div className='address-fields-div'>
                            <AddressFields {...addressFieldProps} />
                            <RecipientNotification {...recipientNotificationProps} />
                        </div>
                    </div>
                )}
                <FormButton {...buttonProps} />
            </form>
        </div>
    );
};

ProductDetail.defaultProps = {
    handleSubmit: () => {},
    addressContext: ADDRESS_CONTEXT.SHIPPING,
    hasPlasticNotification: false,
    anyTouched: false,
    invalid: false,
    submitFailed: false,
    getSubmitMessage: () => cartMessages.submitButtonText,
    countryCode: '',
    stepName: '',
    activeProgram: '',
    activeCatalogs: [],
    cartViewedSegment: false,
    getFaceplates: () => {},
    brandCode: '',
    requireRecipientEmailForPhysicalGift: '',
    recipientName: ''
};


ProductDetail.propTypes = {
    intl: intlShape.isRequired,
    addressContext: PropTypes.string,
    countryCode: PropTypes.string,
    handleSubmit: PropTypes.func,
    stepName: PropTypes.string,
    activeProgram: PropTypes.string,
    activeCatalogs: PropTypes.array,
    cartViewedSegment: PropTypes.bool,
    getFaceplates: PropTypes.func,
    brandCode: PropTypes.string,
    hasPlasticNotification: PropTypes.bool,
    requireRecipientEmailForPhysicalGift: PropTypes.string,
    recipientName: PropTypes.string,
    anyTouched: PropTypes.bool,
    invalid: PropTypes.bool,
    submitFailed: PropTypes.bool,
    getSubmitMessage: PropTypes.func
};

const mapStateToProps = state => ({
    recipientName: newItemRecipientName(state),
    hasPlasticNotification: newItemHasRecipientPlasticNotification(state),
    countryCode: newItemCountry(state),
    requireRecipientEmailForPhysicalGift: getRequireRecipientEmailForPhysicalGift(state),
    activeCatalogs: getActiveProgramCatalogs(state),
    activeProgram: getActiveProgramCode(state),
    getFaceplates: getDefaultFaceplateForProgram(state),
    brandCode: getBrandCode(state),
    cartViewedSegment: parseCartViewed(state)
});

export default connect(mapStateToProps)(injectIntl(newItemReduxForm(ProductDetail, { validate })));
