

import { fromJS } from 'immutable';

const hasBootstrapData = () => !!window.BOOTSTRAP_DATA;
const hasSharedMediaCss = () => !!window.SHARED_MEDIA_CSS;
const hasPreferredValues = () => !!window.BOOTSTRAP_DATA.preferredValues;

export const getEnv = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.ENVIRONMENT;
    }
    return null;
};

export const getIsDesktop = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.isDesktop;
    }
    return false;
};

export const getCapiUrl = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.capiUrl;
    }
    return null;
};

export const getOrderingToken = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.orderingToken;
    }
    return null;
};

export const getSegmentKey = () => {
    try {
        
        return window.BOOTSTRAP_DATA.brand.iapetus;
    } catch (e) {
        return null;
    }
};

export const getBrandCode = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.brandCode;
    }
    return null;
};

export const getBrandUrl = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.brandUrl;
    }
    return '';
};

export const getOrderingTokenServiceCodes = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.ordering_token_service_codes || '';
    }
    return '';
};

export const getDefaultLocaleCode = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.defaultLocaleCode;
    }
    return null;
};

export const getPreferredLocaleCode = () => {
    if (hasBootstrapData()) {
        return window.BOOTSTRAP_DATA.brand.preferredLocaleCode;
    }
    return null;
};

export const getBrandData = () => window.BOOTSTRAP_DATA.brand;

export const getFeatureFlags = () => {
    if (hasBootstrapData()) {
        return fromJS(window.BOOTSTRAP_DATA.featureFlags);
    }
    return null;
};

export const getMasterpassData = () => {
    if (hasBootstrapData()) {
        return fromJS(window.BOOTSTRAP_DATA.MASTERPASS);
    }
    return fromJS({});
};


export const getStaticMediaURL = (suffix) => {
    if (!hasBootstrapData()) {
        return null;
    }

    let url = window.BOOTSTRAP_DATA.staticMediaUrl;
    url = url.replace(/\/$/, '');

    if (suffix) {
        url += suffix;
    }
    return url;
};

export const getSharedMediaCssUrl = () => {
    if (!hasSharedMediaCss()) {
        return '';
    }
    return window.SHARED_MEDIA_CSS;
};

export const getPreferredValues = () => window.BOOTSTRAP_DATA.preferredValues;




export const getPreferredPromoCode = () => {
    if (hasPreferredValues()) {
        return getPreferredValues().promoCode;
    }
    return null;
};


export const getTransactionId = () => {
    let transactionIdVal = getPreferredValues().TransactionId;
    if (!transactionIdVal) {
        
        transactionIdVal = getPreferredValues().transactionId;
    }
    return transactionIdVal;
};


export const getMD = () => {
    let merchantDataVal = getPreferredValues().MD;
    if (!merchantDataVal) {
        
        merchantDataVal = getPreferredValues().mD;
    }
    return merchantDataVal;
};

const hasCookieConsentEnabled = () => (hasBootstrapData()
    && !!window.BOOTSTRAP_DATA.brand
    && !!window.BOOTSTRAP_DATA.brand.cookieConsentEnabled);

export const getCookieConsentEnabled = () => {
    if (hasCookieConsentEnabled()) {
        return window.BOOTSTRAP_DATA.brand.cookieConsentEnabled;
    }
    return null;
};
export const hasProductDetailsPageEnabled = () => {
    if (hasBootstrapData() && window.BOOTSTRAP_DATA.brand) {
        return window.BOOTSTRAP_DATA.brand.productDetailsPageEnabled;
    }
    return false;
};
