import { defineMessages } from 'react-intl';

const headerMessages = defineMessages({
    pageTitle: {
        id: 'header.headerMessages.pageTitle',
        description: 'Page title of the Application',
        defaultMessage: '{brandName} Gift Cards'
    },
    pageHeader: {
        id: 'header.headerMessages.pageHeader',
        description: 'Title heading of the Application - not screen visible',
        defaultMessage: '{brandName} Gift Cards'
    },
    imageAriaLabel: {
        id: 'header.headerMessages.imageAriaLabel',
        description: 'aria-label text for the img tag that contains the brand logo - not screen visible',
        defaultMessage: '{brandName} - opens in a new window'
    },
    linkText: {
        id: 'header.headerMessages.linkText',
        description: 'Text within the header logo link to indicate where clicking the header image '
            + 'will navigate to - not screen visible',
        defaultMessage: '{brandName}'
    },
    logoSrc: {
        id: 'header.headerMessages.logoSrc',
        description: 'Src of the logo',
        defaultMessage: '{brandCode}.svg'
    },
    metaDescriptionContents: {
        id: 'header.headerMessages.metaDescriptionContents',
        description: 'Contents of the meta description tag',
        defaultMessage: '{brandName} Gift Cards'
    }
});

export default headerMessages;
