import { fromJS, Set as ISet, Map as IMap } from 'immutable';


export const FLOW_PROGRESS_STORE_NAME = 'flowProgress';
export const PREFERRED_CLASSIC_GIFT = 'preferredClassicGift';
export const PREFERRED_FACEPLATE_CATEGORY = 'preferredFaceplateCategory';


export const FLOW_STATE_INITIALIZED = '@@storefront/FLOW_STATE_INITIALIZED';
export const UPDATE_FLOW_STATE = '@@storefront/UPDATE_FLOW_STATE';
export const RESET_FLOW_STATE = '@@storefront/RESET_FLOW_STATE';
export const SET_PREFERRED_VALUE = '@@storefront/SET_PREFERRED_VALUE';
export const RESET_PREFERRED_VALUES = '@@storefront/RESET_PREFERRED_VALUES';
export const REMOVE_PREFERRED_VALUE = '@@storefront/REMOVE_PREFERRED_VALUE';
export const REMOVE_FIELD_FROM_FLOW_STATE = '@@storefront/REMOVE_FIELD_FROM_FLOW_STATE';
export const SET_PREFERRED_PAYMENT_VALUE = '@@storefront/SET_PREFERRED_PAYMENT_VALUE';
export const RESET_PREFERRED_PAYMENT_VALUES = '@@storefront/RESET_PREFERRED_PAYMENT_VALUES';
export const SET_FLOW_MESSAGE = '@@storefront/SET_FLOW_MESSAGE';
export const REMOVE_FLOW_MESSAGE = '@@storefront/REMOVE_FLOW_MESSAGE';
export const preferredValueHandledAction = fieldName => `@@storefront/PREFERRED_VALUE_HANDLED_${fieldName}`;

export const initialFlowProgressState = fromJS({
    progress: ISet(),
    preferredValues: IMap(),
    preferredPaymentValues: IMap()
});







export const flowProgressReducer = function flowProgressReducer(state = initialFlowProgressState, action) {
    switch (action.type) {
        case UPDATE_FLOW_STATE:
            return state.mergeIn(['progress'], action.progress);
        case RESET_FLOW_STATE:
            return state.set('progress', ISet());
        case SET_PREFERRED_VALUE:
            return state.setIn(['preferredValues', action.fieldName], action.fieldValue);
        case RESET_PREFERRED_VALUES:
            return state.set('preferredValues', IMap());
        case REMOVE_FIELD_FROM_FLOW_STATE:
            return state.set('progress', state.get('progress').delete(action.fieldName));
        case REMOVE_PREFERRED_VALUE:
            return state.deleteIn(['preferredValues', action.fieldName]);
        case SET_PREFERRED_PAYMENT_VALUE:
            return state.setIn(['preferredPaymentValues', action.fieldName], action.fieldValue);
        case RESET_PREFERRED_PAYMENT_VALUES:
            return state.set('preferredPaymentValues', IMap());
        case SET_FLOW_MESSAGE:
            return state.setIn(['flowMessage', 'pathname'], action.pathname)
                .setIn(['flowMessage', 'message'], action.message);
        case REMOVE_FLOW_MESSAGE:
            return state.delete('flowMessage');
        default:
            return state;
    }
};



export function flowStateInitialized() {
    return {
        type: FLOW_STATE_INITIALIZED
    };
}


export function updateFlowProgress(progress) {
    return {
        type: UPDATE_FLOW_STATE,
        progress
    };
}


export function resetFlowState() {
    return {
        type: RESET_FLOW_STATE
    };
}


export function setPreferredValue(fieldName, fieldValue) {
    return {
        type: SET_PREFERRED_VALUE,
        fieldName,
        fieldValue
    };
}

export function preferredValueHandled(fieldName, skipped = true) {
    return {
        type: preferredValueHandledAction(fieldName),
        skipped
    };
}

export function removePreferredValue(fieldName) {
    return {
        type: REMOVE_PREFERRED_VALUE,
        fieldName
    };
}

export function setPreferredPaymentValue(fieldName, fieldValue) {
    return {
        type: SET_PREFERRED_PAYMENT_VALUE,
        fieldName,
        fieldValue
    };
}

export function resetPreferredPaymentValues() {
    return {
        type: RESET_PREFERRED_PAYMENT_VALUES
    };
}

export function removeFieldFromFlowProgress(fieldName) {
    return {
        type: REMOVE_FIELD_FROM_FLOW_STATE,
        fieldName
    };
}

export function resetPreferredValues() {
    return {
        type: RESET_PREFERRED_VALUES
    };
}

export function setFlowMessage(location, message) {
    return {
        type: SET_FLOW_MESSAGE,
        pathname: location,
        message
    };
}

export function removeFlowMessage(location) {
    return {
        type: REMOVE_FLOW_MESSAGE,
        location
    };
}
