import { defineMessages } from 'react-intl';

const cartMessages = defineMessages({
    cartHeader: {
        id: 'cart.cartMessages.cartHeader',
        description: 'Top-level heading for reviewing details of the user\'s current cart before purchasing',
        defaultMessage: 'Review Your Cart'
    },
    digitalItemLabel: {
        id: 'cart.cartMessages.digitalItemLabel',
        description: 'Section heading used to describe digital gift cards in the cart',
        defaultMessage: '{itemCount, plural, one {eGift Card} other {eGift Cards}}'
    },
    plasticItemLabel: {
        id: 'cart.cartMessages.plasticItemLabel',
        description: 'Section heading used to describe plastic gift cards in the cart',
        defaultMessage: '{itemCount, plural, one {Gift Card} other {Gift Cards}}'
    },
    plasticItemQuantity: {
        id: 'cart.cartMessages.plasticItemQuantity',
        description: 'Message used to describe the amount of plastic gift cards in the shipping group',
        defaultMessage: '{itemCount, plural, one {{itemCount} physical card will be shipped to} other '
            + '{{itemCount} physical cards will be shipped to}}'
    },
    promoItemLabel: {
        id: 'cart.cartMessages.promoItemLabel',
        description: 'Section heading used to describe promotional gift cards in the cart',
        defaultMessage: '{itemCount, plural, one {Promotional Card} other {Promotional Cards}}'
    },
    cartButtonAdd: {
        id: 'cart.cartMessages.cartButtonAdd',
        description: 'Text displayed on the button in the cart review page to allow a user '
            + 'to add another gift card to their cart',
        defaultMessage: 'Add Another Card'
    },
    cartEmptyMessage: {
        id: 'cart.cartMessages.cartEmptyMessage',
        description: 'Notification on the cart review page that the user\'s cart has no items in it',
        defaultMessage: 'Your cart is currently empty. Please add a gift card.'
    },
    cartEmptyButton: {
        id: 'cart.cartMessages.cartEmptyButton',
        description: 'Text displayed on the button in the cart review page to allow a user '
            + 'to add a gift card to their empty cart',
        defaultMessage: 'Add a Gift Card'
    },
    cartOverMax: {
        id: 'cart.cartMessages.cartOverMax',
        description: 'Error message displayed when the total value of the cart exceeds '
            + 'the maximum allowed value of a single purchase',
        defaultMessage: `Your current cart of {total} `
            + `exceeds the maximum allowed order amount of {max}`
    },
    cartAtMax: {
        id: 'cart.cartMessages.cartAtMax',
        description: 'Warning message displayed when the total value of the cart is at '
            + 'the maximum allowed value of a single purchase',
        defaultMessage: `Your current cart of {total} `
            + `is at the maximum allowed order amount. `
            + `You may not add additional items.`
    },
    cartMultipleCurrency: {
        id: 'cart.cartMessages.cartMultipleCurrency',
        description: 'Error message displayed when the cart contains items with different currency codes',
        defaultMessage: `Looks like the cards in your cart have multiple currencies ({currencyCodes}). `
        + `Please remove cards until your cart contains only cards with one type of currency.`
    },
    cartIconLabel: {
        id: 'cart.cartMessages.cartIconLabel',
        description: 'aria-label for the cart icon that navigates the user to the cart review page. '
            + 'This is used only by screen readers for visually impaired users',
        defaultMessage: 'Go to cart page'
    },
    editingItemsWarning: {
        id: 'cart.cartMessages.editingItemsWarning',
        description: 'Warning message displayed when a user tries to checkout while editing an existing item',
        defaultMessage: 'Please complete item editing before checkout.'
    },
    addCardWarning: {
        id: 'cart.cartMessages.addCardWarning',
        description: 'Warning message displayed when a user tries to start adding a new item to the cart '
            + 'while editing an existing item',
        defaultMessage: 'Please complete item editing before adding another card.'
    },
    addCardAtLimitWarning: {
        id: 'cart.cartMessages.addCardAtLimitWarning',
        description: 'Warning message displayed when a user tries to start adding a new item to the cart '
            + 'when the number of line items in the cart is at the limit',
        defaultMessage: 'You have reached the limit of {limit} unique items in your cart. '
            + 'You may not add additional items.'
    },
    addCardExceedsLimitError: {
        id: 'cart.cartMessages.addCardExceedsLimitError',
        description: 'Warning message displayed when a user tries to start adding a new item to the cart '
            + 'when the number of line items in the cart has already exceeded the limit',
        defaultMessage: 'You have exceeded the limit of {limit} unique items in your cart. '
            + 'Please remove {excess} {excess, plural, one {item} other {items}} to proceed.'
    },
    cartButtonCheckoutHeader: {
        id: 'cart.cartMessages.cartButtonCheckoutHeader',
        description: 'Text to be displayed above the checkout button on the cart page.',
        defaultMessage: 'Check Out'
    },
    submitButtonText: {
        id: 'cart.cartMessages.submitButtonText',
        description: 'Text shown on preceding steps on buttons which will move the user to the cart step',
        defaultMessage: 'Add to cart'
    },
    skippedPreCartFlow: {
        id: 'cart.cartMessages.skippedPreCartFlow',
        description: 'Markdown enabled message that is displayed when a card is '
            + 'added to the cart with only smart flow values',
        defaultMessage: '**Success!** We\'ve added a {denom} eGift Card to your cart. '
            + 'Continue to checkout or [add another card]({path})!'
    },
    chooseRecipientHeader: {
        id: 'cart.cartMessages.chooseRecipientHeader',
        description: 'Header for dialog asking user to select a recipient for a bonus card',
        defaultMessage: 'Choose a Recipient'
    },
    chooseRecipientSubHeader: {
        id: 'cart.cartMessages.chooseRecipientSubHeader',
        description: 'Subheader describing a bonus card for which the user is choosing a recipient',
        defaultMessage: 'Choose a recipient for the {denom} bonus gift card'
    },
    chooseRecipientUpdateButtonText: {
        id: 'cart.cartMessages.chooseRecipientUpdateButtonText',
        description: 'Text shown on button that will update a bonus card recipient',
        defaultMessage: 'Update'
    },
    chooseRecipientCancelButtonText: {
        id: 'cart.cartMessages.chooseRecipientCancelButtonText',
        description: 'Text shown on button that will cancel updating a bonus card recipient',
        defaultMessage: 'Cancel'
    },
    chooseRecipientYourself: {
        id: 'cart.cartMessages.chooseRecipientYourself',
        description: 'Pronoun to indicate the user, as recipient of a bonus card',
        defaultMessage: 'You'
    },
    maximumPhysicalCards: {
        id: 'cart.cartMessages.maximumPhysicalCards',
        
        description: 'Text shown at the top of cart page when the cart has reached the maximum amount of physical cards',
        
        defaultMessage: 'You’ve reached the maximum number of physical cards that you can purchase per order. You can continue to add digital cards to your cart. You’ll need to check out before you can purchase more physical cards.'
    },
    physicalCardQuantityAdjusted: {
        id: 'cart.cartMessages.physicalCardQuantityAdjusted',
        
        description: 'Text shown at the top of cart page when physical card quantity has been automatically adjusted',
        
        defaultMessage: 'You’ve reached the maximum number of physical cards that you can purchase per order. We\'ve adjusted the quantity back to {quantity} so that you can still checkout.'
    },
    meetsPhysicalLimitError: {
        id: 'cart.cartMessages.meetsPhysicalLimitError',
        description: 'Warning message displayed when a brand does not sell digital and the cart has reached'
            + 'the item limit.',
        defaultMessage: 'Your current cart has reached the maximum number of {limit} '
            + '{limit, plural, one {physical card} other {physical cards}} '
            + ' allowed in an order. You may not add additional items.'
    },
    cpfLabel: {
        id: 'cart.cartMessages.cpf.title',
        description: 'cpf number field on the cart page',
        defaultMessage: 'Your CPF*'
    },
    cpfNumberEmpty: {
        id: 'cart.cartMessages.cpf.cpfNumberEmpty',
        description: 'Error message displayed when a CPF number is not provided',
        defaultMessage: 'CPF Number is Empty.'
    },
    cpfNumberInvalid: {
        id: 'cart.cartMessages.cpf.cpfNumberInvalid',
        description: 'Error message displayed when a CPF number is not provided',
        defaultMessage: 'CPF Number is Invalid.'
    }
});

export default cartMessages;
