import {
    put,
    call,
    select,
    takeEvery,
    takeLatest,
    take,
    all,
    fork
} from 'redux-saga/effects';
import { Set as ISet } from 'immutable';
import { RETRIEVED_BRAND_CONFIG } from 'capi/redux';

import {
    getSelfBuyEnabled,
    hasClassicCatalogs as getHasClassicCatalogs,
    getLoyaltyProgramValidator,
    getBrandSupportedCardTypes
} from '../brand/brandSelectors';
import { getIntlLocale } from '../intl/intlSelectors';
import { SET_PROGRAM_CODE, clearProgram, setProgramCode } from '../program/programModule';
import { SET_CART_STATUS, CART_STATUS } from '../cart/cartModule';
import { resetFieldValues, changeValue } from '../item/newItemFormModule';
import { getPreferredValues as getUnvalidatedPreferredValues } from '../app/bootstrap';
import {
    AMOUNT_FIELD,
    RECIPIENT_NAME_FIELD,
    SENDER_NAME_FIELD,
    RECIPIENT_EMAIL_FIELD,
    IS_PLASTIC_FIELD,
    FACEPLATE_FIELD,
    IS_SELFBUY_FIELD,
    CURRENCY_FIELD,
    PROGRAM_CODE,
    MESSAGE_FIELD
} from '../item/newItemForm';
import {
    validateProgramCode,
    validateName,
    validateEmail,
    validateAmount,
    validateCardType,
    validateBillingFirstName,
    validateBillingLastName,
    validateLoyaltyNumber,
    validateSelfBuy,
    validateClassicGift,
    validateMessage,
    validateFaceplateCategory
} from '../validate/preferredValuesValidators';
import { formatAmountToString } from '../utils/numberUtils';
import { CARD_TYPE } from '../cardType/cardTypeModule';
import { LOYALTY_FIELD } from '../loyalty/loyaltyModule';
import { ADDRESS_FIRSTNAME, ADDRESS_LASTNAME } from '../address/addressModule';
import { SENDER_EMAIL_FIELD } from '../payment/paymentConstants';
import { getActiveProgramCode, getProgramSelectorList } from '../program/programSelectors';
import { logError } from '../utils/errorUtils';
import { validatePreferredFaceplateCode } from '../faceplate/faceplateSagas';
import alphaNumericNormalizer from '../validate/alphaNumericNormalizer';

import {
    getCartPath,
    getErrorNoRetryPath,
    getConfirmationPath,
    APP_ERROR_TYPES,
    getLocationPathname
} from './flow';
import {
    flowParamsUpdated,
    RESTART_BUY_FLOW,
    SHOW_APP_ERROR,
    ORDER_COMPLETE
} from './flowModule';
import {
    flowStateInitialized,
    setPreferredValue,
    updateFlowProgress,
    setPreferredPaymentValue,
    removePreferredValue,
    PREFERRED_CLASSIC_GIFT,
    PREFERRED_FACEPLATE_CATEGORY,
    preferredValueHandled,
    removeFlowMessage
} from './flowProgressModule';
import {
    getHomePath,
    getFlowMessage,
    getPreferredClassicGift
} from './flowSelectors';
import {
    getURLSearchParams,
    push,
    removeQueryParams,
    replace,
    updateSearch
} from './routing';
import { LOCATION_CHANGE } from './routingModule';



export function* updateProgramCode({ programCode }) {
    const urlParams = yield call(getURLSearchParams);
    const urlProgramCode = yield call([urlParams, urlParams.get], 'programCode');
    const preferredClassicGift = yield select(getPreferredClassicGift);
    if (preferredClassicGift && programCode) {
        yield call(removeQueryParams, ['programCode']);
        yield put(removePreferredValue(PROGRAM_CODE));
    } else if (programCode) {
        if (urlProgramCode !== programCode) {
            yield call(updateSearch, { programCode });
        }
    } else if (urlProgramCode !== null) {
        yield call(removeQueryParams, ['programCode']);
    }
    if (!programCode) {
        yield put(removePreferredValue(PROGRAM_CODE));
    }
}


export function* updateQueryParams() {
    
    
    
    
    const programCode = yield select(getActiveProgramCode);
    yield call(updateProgramCode, { programCode });
    yield put(flowParamsUpdated());
}


export function* updateCartStatus({ status, replaceHistory }) {
    const location = getLocationPathname();
    const cartPath = getCartPath();
    
    if (location !== cartPath && (status === CART_STATUS.PROCESSING || status === CART_STATUS.READY)) {
        if (replaceHistory) {
            yield call(replace, ({ pathname: getCartPath() }));
        } else {
            yield call(push, ({ pathname: getCartPath() }));
        }
    }
}


export function* restartBuyFlow() {
    const homePath = yield select(getHomePath);
    yield put(clearProgram());
    yield put(resetFieldValues());
    yield call(push, ({ pathname: homePath }));
}


export function* showAppError({ errorType }) {
    if (errorType === APP_ERROR_TYPES.NO_RETRY) {
        yield call(replace, { pathname: getErrorNoRetryPath() });
    }
}


export function* completeBuyFlow({ orderDetails }) {
    const selectedLocale = yield select(getIntlLocale);
    const capturedParams = new URLSearchParams(orderDetails.paymentUrlParams);
    
    
    
    const location = {
        pathname: getConfirmationPath(),
        state: orderDetails
    };
    if (!capturedParams.has('locale')) {
        capturedParams.append('locale', selectedLocale);
    }
    location.search = `?${capturedParams.toString()}`;
    yield call(replace, location);
}


export function* updateFlowMessage({ location }) {
    const { pathname } = location;
    const message = yield select(getFlowMessage);
    if (message) {
        const messagePath = message.get('pathname');
        if (messagePath !== pathname) {
            yield put(removeFlowMessage());
        }
    }
}

function* handlePreferredValue(preferredValue = null, options) {
    const {
        storeKey,
        validator,
        formatter,
        onUpdate,
        isPaymentValue,
        skipUpdateProgress
    } = options;
    let isPreferredValueSkipped = true;
    let processedPreferredValue = preferredValue;

    if (processedPreferredValue !== null && validator) {
        if (storeKey === PROGRAM_CODE) {
            const programCodeList = yield select(getProgramSelectorList);
            processedPreferredValue = yield call(validator, preferredValue, programCodeList);
        } else {
            processedPreferredValue = yield call(validator, preferredValue);
        }
    }

    if (processedPreferredValue !== null && formatter) {
        processedPreferredValue = yield call(formatter, processedPreferredValue);
    }

    if (processedPreferredValue !== null) {
        yield* handlePreferredValueWithoutSkip(
            isPaymentValue,
            storeKey,
            processedPreferredValue,
            skipUpdateProgress,
            onUpdate
        );
        isPreferredValueSkipped = false;
    }
    yield put(preferredValueHandled(storeKey, isPreferredValueSkipped));
}

function* handlePreferredValueWithoutSkip(
    isPaymentValue,
    storeKey,
    processedPreferredValue,
    skipUpdateProgress,
    onUpdate
) {
    if (isPaymentValue) {
        
        yield put(setPreferredPaymentValue(storeKey, processedPreferredValue));
    } else {
        
        yield put(setPreferredValue(storeKey, processedPreferredValue));

        
        yield put(changeValue(storeKey, processedPreferredValue));
    }

    if (!skipUpdateProgress) {
        yield put(updateFlowProgress(ISet([storeKey])));
    }

    if (onUpdate) {
        yield call(onUpdate, processedPreferredValue);
    }
}


export function* initializeFlow() {
    
    

    
    
    const actionsToAwait = {
        catalog: take(RETRIEVED_BRAND_CONFIG.CATALOG),
        preferences: take(RETRIEVED_BRAND_CONFIG.PREFERENCES)
    };

    try {
        yield all(actionsToAwait);

        const preferredValues = yield call(getUnvalidatedPreferredValues);

        
        const selfBuyEnabled = yield select(getSelfBuyEnabled);
        const hasClassicCatalogs = yield select(getHasClassicCatalogs);
        const brandSupportedCardTypes = yield select(getBrandSupportedCardTypes);
        const loyaltyNumberRegex = yield select(getLoyaltyProgramValidator);

        

        
        const preferredValuesOptions = {
            currencyCode: {
                storeKey: CURRENCY_FIELD,
                onUpdate: function* onUpdate() {
                    yield call(removeQueryParams, ['currencyCode', 'currency_code']);
                },
                formatter: preferredValue => preferredValue.toUpperCase()
            },
            recipientName: {
                storeKey: RECIPIENT_NAME_FIELD,
                validator: validateName
            },
            senderName: {
                storeKey: SENDER_NAME_FIELD,
                validator: validateName
            },
            recipientEmail: {
                storeKey: RECIPIENT_EMAIL_FIELD,
                validator: validateEmail
            },
            amount: {
                storeKey: AMOUNT_FIELD,
                validator: validateAmount,
                formatter: formatAmountToString
            },
            faceplateCategory: {
                storeKey: PREFERRED_FACEPLATE_CATEGORY,
                validator: validateFaceplateCategory,
                formatter: preferredValue => alphaNumericNormalizer(preferredValue).toLowerCase()
            },
            faceplateCode: {
                storeKey: FACEPLATE_FIELD,
                validator: validatePreferredFaceplateCode
            },
            cardType: {
                storeKey: IS_PLASTIC_FIELD,
                validator: preferredValue => validateCardType(preferredValue, brandSupportedCardTypes),
                formatter: preferredValue => preferredValue === CARD_TYPE.PHYSICAL
            },
            selfBuy: {
                storeKey: IS_SELFBUY_FIELD,
                validator: preferredValue => validateSelfBuy(preferredValue, selfBuyEnabled)
            },
            classicGift: {
                storeKey: PREFERRED_CLASSIC_GIFT,
                validator: preferredValue => validateClassicGift(preferredValue, hasClassicCatalogs)
            },
            programCode: {
                storeKey: PROGRAM_CODE,
                validator: validateProgramCode,
                onUpdate: function* onUpdate(preferredValue) {
                    yield put(setProgramCode(preferredValue));
                }
            },
            billingFirstName: {
                storeKey: ADDRESS_FIRSTNAME,
                validator: validateBillingFirstName,
                isPaymentValue: true,
                skipUpdateProgress: true
            },
            billingLastName: {
                storeKey: ADDRESS_LASTNAME,
                validator: validateBillingLastName,
                isPaymentValue: true,
                skipUpdateProgress: true
            },
            billingPurchaserEmail: {
                storeKey: SENDER_EMAIL_FIELD,
                validator: validateEmail,
                isPaymentValue: true,
                skipUpdateProgress: true
            },
            loyaltyNumber: {
                storeKey: LOYALTY_FIELD,
                validator: preferredValue => validateLoyaltyNumber(loyaltyNumberRegex, preferredValue),
                isPaymentValue: true,
                skipUpdateProgress: true
            },
            message: {
                storeKey: MESSAGE_FIELD,
                validator: validateMessage,
                onUpdate: function* onUpdate() {
                    yield call(removeQueryParams, ['message']);
                }
            }
        };

        const preferredValuesHandlers = [];
        
        
        for (const [fieldName, options] of Object.entries(preferredValuesOptions)) {
            const preferredValue = preferredValues[fieldName];

            
            if (typeof preferredValue !== 'undefined' && preferredValue !== null) {
                preferredValuesHandlers.push(yield fork(handlePreferredValue, preferredValue, options));
            } else {
                yield put(preferredValueHandled(options.storeKey, true));
            }
        }
        yield Promise.all(preferredValuesHandlers);
    } catch (error) {
        logError(`There was an error in initializeFlow: ${error}`);
    } finally {
        
        
        yield put(flowStateInitialized());
    }
}

export function* flowSagasWatcher() {
    yield takeEvery(LOCATION_CHANGE, updateQueryParams);
    yield takeEvery(LOCATION_CHANGE, updateFlowMessage);
    yield takeEvery(SET_PROGRAM_CODE, updateProgramCode);
    yield takeLatest(SET_CART_STATUS, updateCartStatus);
    yield takeLatest(ORDER_COMPLETE, completeBuyFlow);
    yield takeLatest(RESTART_BUY_FLOW, restartBuyFlow);
    yield takeLatest(SHOW_APP_ERROR, showAppError);

    yield fork(initializeFlow);
}
