import { defineMessages } from 'react-intl';


const paymentMessages = defineMessages({
    mainTitle: {
        id: 'payment.paymentMessages.steptitle',
        description: 'Top-level header of the payment step',
        defaultMessage: 'Checkout'
    },
    paymentMethodSubHeading: {
        id: 'payment.paymentMessages.paymentMethodSubHeading',
        description: 'Sub header for the payment method selection section',
        defaultMessage: 'Payment Method'
    },
    paymentSelectionRadio: {
        id: 'payment.paymentMessages.paymentSelectionRadio',
        description: 'Legend message for the payment selection radio buttons',
        defaultMessage: 'Select a payment method'
    },
    creditCardRadioAriaLabel: {
        id: 'payment.paymentMessages.creditCardRadioAriaLabel',
        description: 'message for the cardit card method radio button\'s aria label',
        defaultMessage: 'Selected Credit/Debit Card'
    },
    paypalRadioAriaLabel: {
        id: 'payment.paymentMessages.paypalRadioAriaLabel',
        description: 'message for the PayPal method radio button\'s aria label',
        defaultMessage: 'Selected PayPal'
    },
    applePayRadioAriaLabel: {
        id: 'payment.paymentMessages.applePayRadioAriaLabel',
        description: 'message for the ApplePay method radio button\'s aria label',
        defaultMessage: 'Selected Apple Pay'
    },
    masterPassRadioAriaLabel: {
        id: 'payment.paymentMessages.masterPassRadioAriaLabel',
        description: 'message for the MasterPass method radio button\'s aria label',
        defaultMessage: 'Selected Masterpass'
    },
    submitButtonLabel: {
        id: 'payment.paymentMessages.submitButtonLabel',
        description: 'Label for submit button on payment form which will submit the order with payment',
        defaultMessage: 'Place my order'
    },
    submitButtonProcessingLabel: {
        id: 'payment.paymentMessages.submitButtonProcessingLabel',
        description: 'Label for submit button on payment form which will submit the order with payment which show a processing message',
        defaultMessage: 'Processing...'
    },
    stepUpOverlayMessage: {
        id: 'payment.paymentMessages.stepUpOverlayMessage',
        description: 'Message shown on the Step-Up overlay if 3DS/PSD2 payer authentiction takes a while',
        defaultMessage: 'Hang tight! We are verifying your identity. This could take several minutes.'
    },
    stepUpActionMessage: {
        id: 'payment.paymentMessages.stepUpActionMessage',
        description: 'Message shown for link to manually open 3DS window.',
        defaultMessage: 'click here.'
    },
    stepUpResultsMessage: {
        id: 'payment.paymentMessages.stepUpResultsMessage',
        description: 'Message shown on the Step-Up Results callback endpoint',
        defaultMessage: 'Your authentication is complete. Please close this window to return to your order.'
    },
    stepUpPleaseAuthenticate: {
        id: 'payment.paymentMessages.stepUpPleaseAuthenticate',
        description: 'Message Step-Up authentication form pop-out window',
        defaultMessage: 'Please Authenticate'
    },
    stepUpPopOutBlocked: {
        id: 'payment.paymentMessages.stepUpPopOutBlocked',
        description: 'Error message for Step Up being blocked by browser',
        defaultMessage: 'We need to open a pop-up window to authenticate your payment. Please change your settings to allow pop-ups for this site. If you don\'t see the pop-up after changing your settings, please refresh this page.'
    },
    stepUpPopOutBlockedHeader: {
        id: 'payment.paymentMessages.stepUpPopOutBlockedHeader',
        description: 'Error message header for Step Up being blocked by browser',
        defaultMessage: 'Payment Authentication Needed.'
    },
    editCart: {
        id: 'payment.paymentMessages.editCart',
        description: 'Label for link to leave checkout and edit the cart',
        defaultMessage: 'Edit Cart'
    },
    confirmationHeading: {
        id: 'payment.paymentMessages.confirmationHeading',
        description: 'Top-level header of the payment confirmation',
        defaultMessage: 'Thanks for your order'
    },
    
    confirmationTitle: {
        id: 'payment.paymentMessages.confirmationTitle',
        description: 'Top-level header of the payment confirmation',
        defaultMessage: 'Thanks for your order'
    },
    statusHeadline: {
        id: 'payment.paymentMessages.statusHeadline',
        description: 'Headline for status of the order (StatusHeadline component, payment confirmation page)',
        defaultMessage: 'Your order is currently processing.'
    },
    statusMessage: {
        id: 'payment.paymentMessages.statusMessage',
        description: 'Message with status of the order and instructions (StatusHeadline component, payment confirmation page)',
        defaultMessage: 'You should receive a status email shortly with your order details.'
    },
    orderNumberSubtitle: {
        id: 'payment.paymentMessages.orderNumberSubtitle',
        description: 'Subtitle of the confirmation with the Order Number',
        defaultMessage: 'Order Number: {number}'
    },
    orderSummarySubtitle: {
        id: 'payment.paymentMessages.orderSummarySubtitle',
        description: 'Subtitle of the order Summary',
        defaultMessage: 'Order Summary'
    },
    eGiftCardCount: {
        id: 'payment.paymentMessages.eGiftCardCount',
        description: 'helper text for OF',
        defaultMessage: 'of'
    },
    shareViaLinkCount: {
        id: 'payment.paymentMessages.shareViaLinkCount',
        description: 'Subtitle of the count of share via link giftcards',
        defaultMessage: '{number} eGifts for you to share'
    },
    shareViaLinkCountOne: {
        id: 'payment.paymentMessages.shareViaLinkCountOne',
        description: 'Subtitle of the count of share via link of 1 giftcard',
        defaultMessage: '1 eGift for you to share'
    },
    linksHeldUpPlaceHolderText: {
        id: 'payment.paymentMessages.linksHeldUpPlaceHolderText',
        description: 'place holder text to tell the users that share via links are processing',
        defaultMessage: 'We\'re still processing the {number} eGifts for you to share. You\'ll receive an email with the links when they become available.'
    },
    linksReleasedPlaceHolderText: {
        id: 'payment.paymentMessages.linksReleasedPlaceHolderText',
        description: 'place holder text to tell the users that share via links are ready',
        defaultMessage: 'You can also share the gifts from your order confirmation email'
    },
    totalNumberOfOrdersText: {
        id: 'payment.paymentMessages.totalNumberOfOrdersText',
        description: 'helper text to display the Subtitle for total number of orders',
        defaultMessage: 'You purchased'
    },
    totalNumberOfOrdersNumber: {
        id: 'payment.paymentMessages.totalNumberOfOrdersNumber',
        description: 'helper subtitle for total number of orders, clickable',
        defaultMessage: '{number} cards'
    },
    totalNumberOfOrdersIsOne: {
        id: 'payment.paymentMessages.totalNumberOfOrdersIsOne',
        description: 'helper subtitle for only one total number of orders, clickable',
        defaultMessage: '1 card'
    },
    linkNotSharedYetStatus: {
        id: 'payment.paymentMessages.linkNotSharedYetStatus',
        description: 'helper text to display the status of sharable link',
        defaultMessage: 'Not shared yet'
    },
    giftSharedStatus: {
        id: 'payment.paymentMessages.giftSharedStatus',
        description: 'helper text to display the status of sharable link',
        defaultMessage: 'Gift shared!'
    },
    procSharedStatus: {
        id: 'payment.paymentMessages.procSharedStatus',
        description: 'helper text to display the status of sharable link as processing',
        defaultMessage: 'Preparing link...'
    },
    procStruckSharedStatus: {
        id: 'payment.paymentMessages.procStruckSharedStatus',
        description: 'helper text to display the status of sharable link as struck at processing',
        defaultMessage: 'Still creating link... We\'ll send you an email when it\'s ready.'
    },
    readySharedStatus: {
        id: 'payment.paymentMessages.readySharedStatus',
        description: 'helper text to display the ready to share status',
        defaultMessage: 'Ready to Share'
    },
    eGiftCardForLabel: {
        id: 'payment.paymentMessages.eGiftCardForLabel',
        description: 'helper text to display the e Gift Card For Recipient',
        defaultMessage: 'eGift for {name}'
    },
    shareThiseGiftLabel: {
        id: 'payment.paymentMessages.shareThiseGiftLabel',
        description: 'Subtitle to display Share This e Gift ',
        defaultMessage: 'Share this eGift'
    },
    emailLabel: {
        id: 'payment.paymentMessages.emailLabel',
        description: 'text for share via email icon',
        defaultMessage: 'E-mail'
    },
    copyLabel: {
        id: 'payment.paymentMessages.copyLabel',
        description: 'text for share via copy icon',
        defaultMessage: 'Copy'
    },
    copiedLabel: {
        id: 'payment.paymentMessages.copiedLabel',
        description: 'helper text for copied icon',
        defaultMessage: 'Copied'
    },
    nativeShareContentTitle: {
        id: 'payment.paymentMessages.nativeShareContentTitle',
        description: 'title to display during native share',
        defaultMessage: '{brandName} Gift Card'
    },
    nativeShareDefaultText: {
        id: 'payment.paymentMessages.nativeShareDefaultText',
        description: 'text to display default message during native share',
        defaultMessage: 'Hi {name}, \nHere\'s a {brandName} eGift - enjoy it! \n\n\n {amount} {brandName} eGift.'
    },
    nativeShareCustomMessgText: {
        id: 'payment.paymentMessages.nativeShareCustomMessgText',
        description: 'text to display the custom message by sender during native share',
        defaultMessage: 'Hi {name}, \n{messg} \n\n\n {amount} {brandName} eGift.'
    },
    emailShareSubjectText: {
        id: 'payment.paymentMessages.emailShareSubjectText',
        description: 'text to display the subject during email share',
        defaultMessage: '{Rname}, {Sname} sent you a gift from {brandName}!'
    },
    emailShareDefaultText: {
        id: 'payment.paymentMessages.emailShareDefaultText',
        description: 'text to display the default message by sender during email share',
        defaultMessage: 'Hi {name}, \nHere\'s a {brandName} eGift - enjoy it! \n\n\n{amount} {brandName} eGift {emoji}. \n{url} \n\n'
    },
    emailShareCustomMessgText: {
        id: 'payment.paymentMessages.emailShareCustomMessgText',
        description: 'text to display the custom message by sender during email share',
        defaultMessage: 'Hi {name}, \n{messg} \n\n\n {amount} {brandName} eGift {emoji}. \n{url} \n\n'
    },
    mobileShareButtonText: {
        id: 'payment.paymentMessages.mobileShareButtonText',
        description: 'helper text to display on button',
        defaultMessage: 'Share this gift'
    },
    mobileShareAgainButtonText: {
        id: 'payment.paymentMessages.mobileShareAgainButtonText',
        description: 'helper text to display on button',
        defaultMessage: 'Share this gift again'
    },
    billingToLabel: {
        id: 'payment.paymentMessages.billingToLabel',
        description: 'Label for the billing address',
        defaultMessage: 'Billing To'
    },
    sharePromoHeading: {
        id: 'payment.paymentMessages.sharePromoHeading',
        description: 'Heading for promotion share',
        defaultMessage: 'Share offer with friends'
    },
    orderDetailsTitle: {
        id: 'payment.paymentMessages.orderDetailsTitle',
        description: 'Subheading for the order details section',
        defaultMessage: 'Order details'
    },
    digitalItemLabel: {
        id: 'payment.paymentMessages.digitalItemLabel',
        description: 'Section heading used to describe the digital cards ordered on the order confirmation page',
        defaultMessage: '{itemCount, plural, one {eGift Card} other {eGift Cards}}'
    },
    plasticItemLabel: {
        id: 'payment.paymentMessages.plasticItemLabel',
        description: 'Section heading used to describe plastic cards ordered on the order confirmation page',
        defaultMessage: '{itemCount, plural, one {Gift Card} other {Gift Cards}}'
    },
    promoItemLabel: {
        id: 'payment.paymentMessages.promoItemLabel',
        description: 'Section heading used to describe promotional cards recieved on the order confirmation page',
        defaultMessage: '{itemCount, plural, one {Promotional Card} other {Promotional Cards}}'
    },
    buyAnotherCardButton: {
        id: 'payment.paymentMessages.buyAnotherCardButton',
        description: 'Button to begin placing another order',
        defaultMessage: 'Buy Another Card'
    },
    cardholderAgreementImplicit: {
        id: 'payment.paymentMessages.cardholderAgreementImplicit.md',
        description: 'the text of the implicit cardholder agreement',
        defaultMessage: 'By continuing to purchase, you are agreeing to the Cardholder Agreement.'
    },
    cardholderAgreementExplicit: {
        id: 'payment.paymentMessages.cardholderAgreementExplicit.md',
        description: 'the text of the explicit cardholder agreement',
        defaultMessage: 'By checking this box, you are agreeing to the Cardholder Agreement.'
    },
    selfBuyDeliverySMSLabel: {
        id: 'payment.paymentMessages.selfBuyDeliverySMSLabel',
        description: 'label for sms delivery opt-in toggle',
        defaultMessage: 'Would you like to receive your digital card(s) via SMS?'
    },
    selfBuyDeliverySMSDescripition: {
        id: 'payment.paymentMessages.selfBuyDeliverySMSDescripition',
        description: 'description for sms delivery opt-in toggle',
        defaultMessage: 'By providing your mobile number, you consent to initiate text message(s) from {brandName} to your number with the eGift Card link(s). In order to receive your eGift card(s) via SMS, you must provide a valid mobile phone number.'
    }
});

export default paymentMessages;
